import React, { useState, useEffect } from 'react'
import {
	Row,
	Col,
	Form,
	Select,
	Button,
	message,
	Typography,
	Tooltip,
	Modal,
	Card,
	Table,
	Tag,
} from 'antd'
import {
	PlusOutlined,
	EditOutlined,
	InfoCircleOutlined,
	DeleteOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Text } = Typography
const { Option } = Select

// Define policy type and condition options
const typeOptions = [
	{ label: 'Device Ownership', value: 'deviceOwnership' },
	{ label: 'Manufacturer', value: 'manufacturer' },
	{ label: 'Model', value: 'model' },
	{ label: 'Operating System', value: 'operatingSystem' },
]

const dynamicOptions = {
	deviceOwnership: ['Company', 'Personal'],
	manufacturer: ['Apple', 'Samsung', 'Dell', 'HP', 'Lenovo'],
	operatingSystem: ['Android', 'iOS', 'Windows', 'macOS', 'Linux'],
	model: [
		'MacBook',
		'XPS 13',
		'EliteBook',
		'ThinkPad',
		'Surface Pro',
		'Latitude',
		'Inspiron',
		'Yoga',
	],
}

const conditionOptions = [
	{ label: 'Equals', value: 'equals' },
	{ label: 'Not Equals', value: 'notEquals' },
]

const DevicesPolicies = ({ onCreatePolicy, loadingPolicy }) => {
	const searchParams = new URLSearchParams(window.location.search)
	const [form] = Form.useForm()
	const [selectedType, setSelectedType] = useState(null)
	const [policies, setPolicies] = useState([])
	const [editingKey, setEditingKey] = useState(null)
	const [loading, setLoading] = useState(false)
	const [notification, notificationHolder] = message.useMessage()
	const [payload, setPayload] = useState({})
	const [policySaved, setPolicySaved] = useState(false)
	const navigate = useNavigate()
	const isEditMode = searchParams.get('edit') === 'true'

	// Helper function to safely retrieve data from localStorage
	const getLocalStorageData = (key) => {
		try {
			const data = localStorage.getItem(key)
			return data ? JSON.parse(data) : null
		} catch (error) {
			console.error(`Error parsing localStorage key "${key}":`, error)
			return null
		}
	}

	//below all function is to retreive data from local storage and populate the form

	// Assuming storedPolicies is the object retrieved from localStorage
	const storedPolicies = {
		os: [],
		manufacturer: [],
		model: [],
		is_personal: false,
		filter: ['device.deviceOwnership -eq "Company"'],
	}

	function convertStoredPolicies(storedPolicies) {
		const result = []

		// Parse the filter array to determine deviceOwnership
		if (storedPolicies.filter && storedPolicies.filter.length > 0) {
			const ownershipFilter = storedPolicies.filter.find((item) =>
				item.includes('device.deviceOwnership')
			)
			if (ownershipFilter) {
				// Extract the value after the equals sign
				const match = ownershipFilter.match(
					/device\.deviceOwnership\s-\w\w\s"([^"]+)"/
				)
				if (match && match[1]) {
					result.push({
						key: Date.now(),
						type: 'deviceOwnership',
						condition: 'equals',
						options: [match[1]],
					})
				}
			}
		}

		// If OS array is not empty
		if (storedPolicies.os && storedPolicies.os.length > 0) {
			result.push({
				key: Date.now() + 1,
				type: 'operatingSystem',
				condition: 'equals',
				options: storedPolicies.os,
			})
		}

		// If manufacturer array is not empty
		if (storedPolicies.manufacturer && storedPolicies.manufacturer.length > 0) {
			result.push({
				key: Date.now() + 2,
				type: 'manufacturer',
				condition: 'equals',
				options: storedPolicies.manufacturer,
			})
		}

		// If model array is not empty
		if (storedPolicies.model && storedPolicies.model.length > 0) {
			result.push({
				key: Date.now() + 3,
				type: 'model',
				condition: 'equals',
				options: storedPolicies.model,
			})
		}

		return result
	}

	// Use the function where you set your policies state:
	useEffect(() => {
		const basicPolicyDraft = getLocalStorageData(
			'policyDraft.basicPolicyInformation'
		)
		if (basicPolicyDraft) {
			const storedPolicies = basicPolicyDraft.policyJson?.dit?.devices
			if (storedPolicies) {
				const transformedPolicies = convertStoredPolicies(storedPolicies)
				setPolicies(transformedPolicies)
			}
		} else {
			message.error(
				'Basic Policy Information is missing. Please complete it first!'
			)
		}
	}, [])

	// Handler to add or edit a policy
	const addOrEditPolicy = (values) => {
		const { options, ...rest } = values
		const processedOptions = Array.isArray(options) ? options : [options]

		if (editingKey !== null) {
			// Update existing policy
			setPolicies((prevPolicies) =>
				prevPolicies.map((policy) =>
					policy.key === editingKey
						? { ...policy, ...rest, options: processedOptions }
						: policy
				)
			)
			// message.success('Policy updated successfully!')
		} else {
			// Add new policy
			const newPolicy = {
				key: Date.now(),
				...rest,
				options: processedOptions,
			}
			setPolicies([...policies, newPolicy])
			// message.success('Policy added successfully!')
		}
		form.resetFields()
		setEditingKey(null)
		setSelectedType(null)
	}

	// Handler to remove a policy
	const removePolicy = (key) => {
		Modal.confirm({
			title: 'Are you sure you want to remove this policy?',
			content: 'This action cannot be undone.',
			onOk: () => {
				setPolicies((prevPolicies) =>
					prevPolicies.filter((policy) => policy.key !== key)
				)
				message.info('Policy removed.')
			},
		})
	}

	// Handler to initiate policy editing
	const editPolicy = (policy) => {
		setEditingKey(policy.key)
		setSelectedType(policy.type)
		form.setFieldsValue({ ...policy })
	}

	// Handler to save policies, send to API, and clear localStorage
	const handleSavePolicy = () => {
		setLoading(true)
		// Retrieve existing policyDraft.basicPolicyInformation from localStorage
		const basicPolicyDraft = getLocalStorageData(
			'policyDraft.basicPolicyInformation'
		)
		if (!basicPolicyDraft) {
			message.error(
				'Basic Policy Information is missing. Please complete it first!'
			)
			setLoading(false)
			return
		}

		// Clone existing policy data to avoid direct mutations
		const updatedPolicyDraft = { ...basicPolicyDraft }

		// Ensure the 'policyJson', 'dit', and 'devices' keys exist
		if (!updatedPolicyDraft.policyJson) {
			updatedPolicyDraft.policyJson = {}
		}
		if (!updatedPolicyDraft.policyJson.dit) {
			updatedPolicyDraft.policyJson.dit = {}
		}
		if (!updatedPolicyDraft.policyJson.dit.devices) {
			updatedPolicyDraft.policyJson.dit.devices = {}
		}

		// Integrate the current policies into 'policyJson.dit.devices.policies'
		updatedPolicyDraft.policyJson.dit.devices.policies = policies

		// Construct the filter query based on policies
		const buildFilterQuery = (policies) => {
			const attributeMap = {
				deviceOwnership: 'device.deviceOwnership',
				manufacturer: 'device.manufacturer',
				model: 'device.model',
				operatingSystem: 'device.operatingSystem',
			}

			const queryParts = policies.map((policy) => {
				const { type, condition, options } = policy
				const attribute = attributeMap[type] || type

				// Handle single option
				if (options.length === 1) {
					return `${attribute} -${condition === 'equals' ? 'eq' : 'ne'} "${
						options[0]
					}"`
				}

				// Handle multiple options
				const operator = condition === 'equals' ? 'OR' : 'AND'
				const conditionStr = options
					.map(
						(option) =>
							`${attribute} -${
								condition === 'equals' ? 'eq' : 'ne'
							} "${option}"`
					)
					.join(` ${operator} `)
				return `(${conditionStr})`
			})

			return queryParts.join(' AND ')
		}

		const filterQuery = buildFilterQuery(policies)

		// Populate devices section based on policies
		const devices = {
			os: [],
			manufacturer: [],
			model: [],
			is_personal: true, // Default value; adjust based on policies if needed
			filter: [filterQuery],
		}

		policies.forEach((policy) => {
			const { type, options, condition } = policy
			if (type === 'operatingSystem' && condition === 'equals') {
				devices.os.push(...options)
			}
			if (type === 'manufacturer' && condition === 'equals') {
				devices.manufacturer.push(...options)
			}
			if (type === 'model') {
				if (condition === 'equals') {
					devices.model.push(...options)
				}
				// Handle 'notEquals' if needed
			}
			if (type === 'deviceOwnership') {
				devices.is_personal = options.includes('Personal') ? true : false
			}
		})

		// Remove duplicates
		devices.os = Array.from(new Set(devices.os))
		devices.manufacturer = Array.from(new Set(devices.manufacturer))
		devices.model = Array.from(new Set(devices.model))

		// Update the devices section in the policy draft
		updatedPolicyDraft.policyJson.dit.devices = devices

		// Define the payload for the API
		setPayload(updatedPolicyDraft)

		// Save the updated policy draft back to localStorage
		try {
			localStorage.setItem(
				'policyDraft.basicPolicyInformation',
				JSON.stringify(updatedPolicyDraft)
			)
			setPolicySaved(true)
		} catch (error) {
			console.error('Error saving policies to localStorage:', error)
			message.error('Failed to save policies. Please try again.')
			setLoading(false)
			return
		}
	}

	const handleCreatePolicy = () => {
		onCreatePolicy(payload)
	}

	const handleCancel = () => {
		Modal.confirm({
			title: 'Are you sure you want to cancel?',
			content: 'All unsaved changes will be lost.',
			onOk: () => {
				localStorage.removeItem('policyDraft.basicPolicyInformation')
				localStorage.removeItem('formSessionData')
				localStorage.removeItem('editPolicyData')

				navigate('/pam/policy/v2')
			},
		})
	}

	const columns = [
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			render: (type) => (
				<Tag color="blue">
					{typeOptions.find((t) => t.value === type)?.label || type}
				</Tag>
			),
		},
		{
			title: 'Condition',
			dataIndex: 'condition',
			key: 'condition',
			render: (condition) => (
				<Tag color="green">
					{conditionOptions.find((c) => c.value === condition)?.label ||
						condition}
				</Tag>
			),
		},
		{
			title: 'Options',
			dataIndex: 'options',
			key: 'options',
			render: (options) =>
				Array.isArray(options) ? (
					options.map((option) => (
						<Tag color="geekblue" key={option}>
							{option}
						</Tag>
					))
				) : (
					<Tag color="geekblue">{options}</Tag>
				),
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => (
				<>
					<Button
						type="link"
						icon={<EditOutlined />}
						onClick={() => editPolicy(record)}
					/>
					<Button
						type="link"
						danger
						icon={<DeleteOutlined />}
						onClick={() => removePolicy(record.key)}
					/>
				</>
			),
		},
	]

	return (
		<div>
			{notificationHolder}

			<Card>
				<Form form={form} layout="vertical" onFinish={addOrEditPolicy}>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								name="type"
								label={
									<span>
										Type{' '}
										<Tooltip title="Select the type of policy.">
											<InfoCircleOutlined />
										</Tooltip>
									</span>
								}
								rules={[{ required: true, message: 'Please select a type.' }]}
							>
								<Select
									placeholder="Select Type"
									onChange={(value) => setSelectedType(value)}
								>
									{typeOptions.map((type) => (
										<Option key={type.value} value={type.value}>
											{type.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="condition"
								label={
									<span>
										Condition{' '}
										<Tooltip title="Select the condition.">
											<InfoCircleOutlined />
										</Tooltip>
									</span>
								}
								rules={[
									{ required: true, message: 'Please select a condition.' },
								]}
							>
								<Select placeholder="Select Condition">
									{conditionOptions.map((condition) => (
										<Option key={condition.value} value={condition.value}>
											{condition.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="options"
								label={
									<span>
										Options{' '}
										<Tooltip title="Provide the options for the policy.">
											<InfoCircleOutlined />
										</Tooltip>
									</span>
								}
								rules={[{ required: true, message: 'Please provide options.' }]}
							>
								{selectedType === 'deviceOwnership' ? (
									<Select placeholder="Select Options">
										{dynamicOptions[selectedType]?.map((option) => (
											<Option key={option} value={option}>
												{option}
											</Option>
										))}
									</Select>
								) : selectedType === 'model' ? (
									<Select mode="multiple" placeholder="Select Models">
										{dynamicOptions[selectedType]?.map((option) => (
											<Option key={option} value={option}>
												{option}
											</Option>
										))}
									</Select>
								) : selectedType === 'operatingSystem' ? (
									<Select
										mode="multiple"
										placeholder="Select Operating Systems"
									>
										{dynamicOptions[selectedType]?.map((option) => (
											<Option key={option} value={option}>
												{option}
											</Option>
										))}
									</Select>
								) : (
									<Select mode="multiple" placeholder="Select options">
										{dynamicOptions[selectedType]?.map((option) => (
											<Option key={option} value={option}>
												{option}
											</Option>
										))}
									</Select>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Row justify="end">
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								icon={editingKey !== null ? <EditOutlined /> : <PlusOutlined />}
							>
								{editingKey !== null ? 'Update' : 'Add'}
							</Button>
						</Form.Item>
					</Row>
					<Row>
						<Col>
							<Text type="secondary">
								"All these fields are optional and managed by Authnull agent".
							</Text>
						</Col>
					</Row>
				</Form>
			</Card>
			{/* {console.log('these are the policies ', policies)} */}

			{policies.length > 0 && (
				<>
					<Card
						type="inner"
						title="Device Policies"
						style={{ marginTop: '24px' }}
					>
						<Table
							dataSource={policies}
							columns={columns}
							pagination={false}
							rowKey="key"
						/>
						{isEditMode && (
							<Row>
								<Col style={{ marginTop: '10px' }}>
									<Text type="secondary">
										"Dont keep this policy field empty"
									</Text>
								</Col>
							</Row>
						)}
					</Card>
					<Row justify="end" style={{ marginTop: '24px' }}>
						<Button onClick={handleCancel} style={{ marginRight: '16px' }}>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={handleSavePolicy}
							style={{ marginRight: '16px' }}
						>
							{isEditMode ? 'Update ' : 'Save '}
						</Button>
						<Button
							type="primary"
							onClick={handleCreatePolicy}
							loading={loadingPolicy}
							disabled={!policySaved}
						>
							{isEditMode ? 'Update Policy ' : 'Create Policy '}
						</Button>
					</Row>
				</>
			)}
		</div>
	)
}

export default DevicesPolicies
