import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Option } = Select

const workflowOptions = [
	{ label: 'Any', value: 'any' },
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
]

const PolicyApprovalWorkflowFilterFields = ({ form, filters, setFilters }) => {
	return (
		<>
			{/* Require Activation Approval Filter */}
			<Form.Item
				label={
					<span>
						Require Activation Approval{' '}
						<Tooltip title="Filter policies based on whether activation approval is required.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['policyApprovalWorkflow', 'isActivationApprovalRequired']}
			>
				<Select
					placeholder="Select an option"
					allowClear
					onChange={(value) => {
						setFilters({
							...filters,
							policyApprovalWorkflow: {
								...filters.policyApprovalWorkflow,
								isActivationApprovalRequired: value,
							},
						})
					}}
				>
					{workflowOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			</Form.Item>

			{/* Require Justification Filter */}
			<Form.Item
				label={
					<span>
						Require Justification{' '}
						<Tooltip title="Filter policies based on whether justification is required.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['policyApprovalWorkflow', 'mustProvideJustification']}
			>
				<Select
					placeholder="Select an option"
					allowClear
					onChange={(value) => {
						setFilters({
							...filters,
							policyApprovalWorkflow: {
								...filters.policyApprovalWorkflow,
								mustProvideJustification: value,
							},
						})
					}}
				>
					{workflowOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			</Form.Item>

			{/* Require Ticket Filter */}
			<Form.Item
				label={
					<span>
						Require Ticket{' '}
						<Tooltip title="Filter policies based on whether a ticket is required.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['policyApprovalWorkflow', 'mustProvideTicket']}
			>
				<Select
					placeholder="Select an option"
					allowClear
					onChange={(value) => {
						setFilters({
							...filters,
							policyApprovalWorkflow: {
								...filters.policyApprovalWorkflow,
								mustProvideTicket: value,
							},
						})
					}}
				>
					{workflowOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			</Form.Item>
		</>
	)
}

export default PolicyApprovalWorkflowFilterFields
