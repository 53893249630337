// src/components/AdvancedFilterDrawer/filters/NetworkAccessFilterFields.js

import React from 'react'
import { Form, Row, Col, Select, Input, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Option } = Select

const comparatorOptions = [
	{ label: 'Includes', value: 'includes' },
	{ label: 'Excludes', value: 'excludes' },
	{ label: 'Exact Match', value: 'exact' },
]

const ipPattern =
	/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}$/

const NetworkAccessFilterFields = ({ form, filters, setFilters }) => {
	return (
		<>
			{/* Network Range Filter */}
			<Form.Item
				label={
					<span>
						Network Range{' '}
						<Tooltip title="Filter policies based on network IP ranges using a comparator and IP address.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['networkAccess', 'networkRange']}
				rules={[
					{
						validator: (_, value) => {
							if (!value) return Promise.resolve()
							const { comparator, lowerIP, upperIP, exactIP } = value

							if (!comparator) {
								return Promise.reject(
									new Error('Comparator is required for Network Range.')
								)
							}

							if (comparator === 'exact') {
								if (!exactIP) {
									return Promise.reject(
										new Error(
											'Exact IP is required for Exact Match comparator.'
										)
									)
								}
							} else if (
								comparator === 'includes' ||
								comparator === 'excludes'
							) {
								if (!lowerIP || !upperIP) {
									return Promise.reject(
										new Error(
											'Both Lower IP and Upper IP are required for Includes and Excludes comparators.'
										)
									)
								}
								// Validate that upperIP is greater than or equal to lowerIP
								const ipToNumber = (ip) =>
									ip
										.split('.')
										.reduce((acc, octet) => acc * 256 + parseInt(octet, 10), 0)

								if (ipToNumber(value.upperIP) < ipToNumber(value.lowerIP)) {
									return Promise.reject(
										new Error(
											'Upper IP must be greater than or equal to Lower IP.'
										)
									)
								}
							}

							return Promise.resolve()
						},
					},
				]}
			>
				<Row gutter={8}>
					{/* Comparator Selection */}
					<Col span={8}>
						<Form.Item
							name={['networkAccess', 'networkRange', 'comparator']}
							noStyle
							rules={[
								{ required: true, message: 'Please select a comparator.' },
							]}
						>
							<Select
								placeholder="Comparator"
								allowClear
								style={{ width: '100%' }}
								onChange={(value) => {
									setFilters({
										...filters,
										networkAccess: {
											...filters.networkAccess,
											networkRange: {
												...filters.networkAccess?.networkRange,
												comparator: value,
												// Reset dependent fields when comparator changes
												exactIP:
													value === 'exact'
														? filters.networkAccess?.networkRange?.exactIP
														: undefined,
												lowerIP:
													value === 'includes' || value === 'excludes'
														? filters.networkAccess?.networkRange?.lowerIP
														: undefined,
												upperIP:
													value === 'includes' || value === 'excludes'
														? filters.networkAccess?.networkRange?.upperIP
														: undefined,
											},
										},
									})
								}}
							>
								{comparatorOptions.map((comp) => (
									<Option key={comp.value} value={comp.value}>
										{comp.label}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					{/* Exact IP Input (only for Exact Match) */}
					<Col span={16}>
						<Form.Item
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.networkAccess?.networkRange?.comparator !==
								currentValues.networkAccess?.networkRange?.comparator
							}
							noStyle
						>
							{({ getFieldValue }) => {
								const comparator = getFieldValue([
									'networkAccess',
									'networkRange',
									'comparator',
								])
								return comparator === 'exact' ? (
									<Form.Item
										name={['networkAccess', 'networkRange', 'exactIP']}
										noStyle
										rules={[
											{
												required: true,
												message: 'Please enter the exact IP address.',
											},
											{
												pattern: ipPattern,
												message: 'Please enter a valid IP address.',
											},
										]}
									>
										<Input
											placeholder="Exact IP (e.g., 192.168.1.1)"
											onChange={(e) => {
												const value = e.target.value
												setFilters({
													...filters,
													networkAccess: {
														...filters.networkAccess,
														networkRange: {
															...filters.networkAccess?.networkRange,
															exactIP: value,
														},
													},
												})
											}}
										/>
									</Form.Item>
								) : null
							}}
						</Form.Item>

						{/* Lower and Upper IP Inputs (for Includes and Excludes) */}
						<Form.Item
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.networkAccess?.networkRange?.comparator !==
								currentValues.networkAccess?.networkRange?.comparator
							}
							noStyle
						>
							{({ getFieldValue }) => {
								const comparator = getFieldValue([
									'networkAccess',
									'networkRange',
									'comparator',
								])
								return comparator === 'includes' ||
									comparator === 'excludes' ? (
									<Row gutter={8}>
										{/* Lower IP */}
										<Col span={12}>
											<Form.Item
												name={['networkAccess', 'networkRange', 'lowerIP']}
												noStyle
												rules={[
													{
														required: true,
														message: 'Please enter the lower IP address.',
													},
													{
														pattern: ipPattern,
														message: 'Please enter a valid lower IP address.',
													},
												]}
											>
												<Input
													placeholder="Lower IP (e.g., 192.168.1.1)"
													onChange={(e) => {
														const value = e.target.value
														setFilters({
															...filters,
															networkAccess: {
																...filters.networkAccess,
																networkRange: {
																	...filters.networkAccess?.networkRange,
																	lowerIP: value,
																},
															},
														})
													}}
												/>
											</Form.Item>
										</Col>

										{/* Upper IP */}
										<Col span={12}>
											<Form.Item
												name={['networkAccess', 'networkRange', 'upperIP']}
												noStyle
												rules={[
													{
														required: true,
														message: 'Please enter the upper IP address.',
													},
													{
														pattern: ipPattern,
														message: 'Please enter a valid upper IP address.',
													},
													({ getFieldValue }) => ({
														validator(_, value) {
															const lowerIP = getFieldValue([
																'networkAccess',
																'networkRange',
																'lowerIP',
															])
															if (!value || !lowerIP) {
																return Promise.resolve()
															}
															const ipToNumber = (ip) =>
																ip
																	.split('.')
																	.reduce(
																		(acc, octet) =>
																			acc * 256 + parseInt(octet, 10),
																		0
																	)
															if (ipToNumber(value) >= ipToNumber(lowerIP)) {
																return Promise.resolve()
															}
															return Promise.reject(
																new Error(
																	'Upper IP must be greater than or equal to Lower IP.'
																)
															)
														},
													}),
												]}
											>
												<Input
													placeholder="Upper IP (e.g., 192.168.1.255)"
													onChange={(e) => {
														const value = e.target.value
														setFilters({
															...filters,
															networkAccess: {
																...filters.networkAccess,
																networkRange: {
																	...filters.networkAccess?.networkRange,
																	upperIP: value,
																},
															},
														})
													}}
												/>
											</Form.Item>
										</Col>
									</Row>
								) : null
							}}
						</Form.Item>
					</Col>
				</Row>
			</Form.Item>
		</>
	)
}

export default NetworkAccessFilterFields
