import { InboxOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Col, Modal, Row, Select } from "antd";
import React from "react";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const POLICY_TYPE_OPTIONS = [
  { label: "Endpoints", value: "endpoints" },
  { label: "Active Directory", value: "AD" },
  { label: "Radius", value: "radius" },
];

export default function PolicyImpact({ isModelOpen, handleModalClose }) {
  const COLUMNS = [
    { headerName: "Wallet User", field: "walletUser", flex: 1 },
    { headerName: "User", field: "user", flex: 1 },
    { headerName: "Policy Type", field: "policyType", flex: 1 },
    { headerName: "Action", field: "action", flex: 1 },
  ];

  return (
    <Modal
      title="Policy Impact"
      visible={isModelOpen}
      footer={null}
      width={900}
      onCancel={handleModalClose}
    >
      <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Col span={6}>
          <Select style={{ width: "100%" }} placeholder="Select Policy Type" allowClear>
            {POLICY_TYPE_OPTIONS.map((option, index) => (
              <Select.Option key={index + 1} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>

      <div>
        <DataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          style={{ border: "none", width: "100%", overflow: "auto" }}
          rows={[]}
          columns={COLUMNS}
        />
      </div>
    </Modal>
  );
}
