import React, { useState, useEffect } from 'react'
import {
	Row,
	Col,
	Form,
	Switch,
	Button,
	Typography,
	message,
	Modal,
	Card,
} from 'antd'
import { useNavigate } from 'react-router-dom'

const { Text } = Typography

const getLocalStorageData = (key) => {
	try {
		const data = localStorage.getItem(key)
		return data ? JSON.parse(data) : null
	} catch (error) {
		console.error(`Error parsing localStorage key "${key}":`, error)
		return null
	}
}

const setLocalStorageData = (key, value) => {
	try {
		localStorage.setItem(key, JSON.stringify(value))
	} catch (error) {
		console.error(`Error setting localStorage key "${key}":`, error)
	}
}

const OfflineAccess = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
	const searchParams = new URLSearchParams(window.location.search)
	const navigate = useNavigate()
	const [form] = Form.useForm()
	const [isOfflineAccessEnabled, setOfflineAccessEnabled] = useState(false)
	const [loading, setLoading] = useState(false)
	const [notification, notificationHolder] = message.useMessage()
	const [policySaved, setPolicySaved] = useState(false)
	const [payload, setPayload] = useState()
	const isEditMode = searchParams.get('edit') === 'true'

	useEffect(() => {
		const basicPolicyData = getLocalStorageData(
			'policyDraft.basicPolicyInformation'
		)
		if (basicPolicyData) {
			const { policyJson } = basicPolicyData
			const dit = policyJson?.dit || {}
			if (typeof dit.offline_access === 'boolean') {
				setOfflineAccessEnabled(dit.offline_access)
				form.setFieldsValue({
					isOfflineAccessEnabled: dit.offline_access,
				})
			}
		} else {
			notification.error({
				content:
					'Basic Policy Information is missing. Please complete it first!',
			})
		}
	}, [form, notification])

	const handleSavePolicy = async () => {
		try {
			setLoading(true)

			const values = await form.validateFields()

			const basicPolicyData = getLocalStorageData(
				'policyDraft.basicPolicyInformation'
			)
			if (!basicPolicyData) {
				notification.error({
					content:
						'Basic Policy Information is missing. Please complete it first!',
				})
				setLoading(false)
				return null
			}

			const updatedBasicPolicyData = { ...basicPolicyData }
			if (!updatedBasicPolicyData.policyJson) {
				updatedBasicPolicyData.policyJson = {}
			}
			if (!updatedBasicPolicyData.policyJson.dit) {
				updatedBasicPolicyData.policyJson.dit = {}
			}

			updatedBasicPolicyData.policyJson.dit.offline_access =
				values.isOfflineAccessEnabled

			setLocalStorageData(
				'policyDraft.basicPolicyInformation',
				updatedBasicPolicyData
			)
			setPayload(updatedBasicPolicyData)
			setPolicySaved(true)

			return updatedBasicPolicyData
		} catch (errorInfo) {
			console.error('Validation Failed:', errorInfo)
			message.error({
				content: 'Please fix the validation errors before saving.',
			})
			return null
		} finally {
			setLoading(false)
		}
	}

	const handleSaveAndNext = async () => {
		const updatedData = await handleSavePolicy()
		if (updatedData) {
			onNavigateNext()
		}
	}

	const handleSaveAndCreate = async () => {
		const updatedData = await handleSavePolicy()
		if (updatedData) {
			onCreatePolicy({
				orgId: updatedData.orgId,
				tenantId: updatedData.tenantId,
				policyName: updatedData.policyName,
				policyType: updatedData.policyType,
				policyJson: updatedData.policyJson,
			})
		}
	}

	const handleSaveOnly = async () => {
		await handleSavePolicy()
	}

	const handleCancel = () => {
		Modal.confirm({
			title: 'Are you sure you want to cancel?',
			content: 'All unsaved changes will be lost.',
			onOk: () => {
				localStorage.removeItem('policyDraft.basicPolicyInformation')
				localStorage.removeItem('formSessionData')
				localStorage.removeItem('editPolicyData')
				navigate('/pam/policy/v2')
			},
		})
	}

	return (
		<div>
			{notificationHolder}
			<Card style={{ marginBottom: '24px' }}>
				<Form form={form} layout="vertical">
					<Row
						align="middle"
						justify="space-between"
						style={{ marginBottom: '16px' }}
					>
						<Col>
							<p style={{ fontWeight: '500' }}>Enable Offline Access</p>
						</Col>
						<Col>
							<Form.Item
								name="isOfflineAccessEnabled"
								valuePropName="checked"
								initialValue={isOfflineAccessEnabled}
							>
								<Switch
									checked={isOfflineAccessEnabled}
									onChange={setOfflineAccessEnabled}
									aria-label="Toggle Offline Access"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col>
							<Text type="secondary">
								Users will log in using cached credentials for Single Sign-On
								(SSO) when offline.
							</Text>
						</Col>
					</Row>
					<Row>
						<Col style={{ marginTop: '10px' }}>
							<Text type="secondary">
								"All these fields are optional and managed by Authnull agent".
							</Text>
						</Col>
					</Row>
				</Form>
			</Card>

			<Row justify="end">
				<Button onClick={handleCancel} style={{ marginRight: '16px' }}>
					Cancel
				</Button>

				<Button
					type="primary"
					onClick={handleSaveAndNext}
					loading={loading}
					style={{ marginRight: '16px' }}
				>
					{isEditMode ? 'Update and Next' : 'Save and Next'}
				</Button>
				<Button
					type="primary"
					onClick={handleSaveAndCreate}
					loading={loadingPolicy}
				>
					{isEditMode ? 'Update Policy' : 'Create Policy'}
				</Button>
			</Row>
		</div>
	)
}

export default OfflineAccess
