//DIRECTORY AND PRIVELAGED ACCESS MANAGEMENT
export const REACT_APP_PAM_API = process.env.REACT_APP_API_PAM_URL;

export const REACT_APP_API_PAM_URL = process.env.REACT_APP_API_PAM_URL;

//REQUEST

export const REACT_APP_API_PAM_V1_URL = process.env.REACT_APP_API_PAM_V1_URL;

//DIDS
export const REACT_APP_API_DID_URL = process.env.REACT_APP_API_DID_URL;

//TRASACTIONS LOGS
export const REACT_APP_API_TRANS_URL = process.env.REACT_APP_API_TRANS_URL;

//OKTA
export const REACT_APP_API_OKTA = process.env.REACT_APP_API_OKTA;
export const REACT_APP_API = process.env.REACT_APP_API;

//Domain
export const REACT_APP_URL = process.env.REACT_APP_URL;

//SERVICE ACCOUNT
export const REACT_APP_API_SAM_URL = process.env.REACT_APP_API_SAM_URL;

//SIGNUP
export const REACT_APP_API_TENANTS_URL = process.env.REACT_APP_API_TENANTS_URL;

//DASHBOARD
export const REACT_APP_API_DASHBOARD_URL = process.env.REACT_APP_API_DASHBOARD_URL;

// JUMPSERVER CONNECTION PORT
export const JUMPSERVER_CONNECTION_PORT = Number(process.env.REACT_APP_JUMPSERVER_CONNECTION_PORT);

// AI AGENT URL
export const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;

//ORGNIZATION ID
export const ORG_ID = () => {
  let OrgId = localStorage.getItem("OrgId");
  return Number(OrgId);
};

//OKTA TOKEN
export const AUTH_TOKEN = () => {
  let token = localStorage.getItem("token");
  let domainUrl = localStorage.getItem("URL");
  return `${token}&DOMAIN&${domainUrl}&ORGID&${ORG_ID()}`;
};

export const AUTH_TOKEN_ORG = () => {
  let domainUrl = localStorage.getItem("URL");
  let token = localStorage.getItem("token");
  let orgId = localStorage.getItem("OrgId");
  return `${token}&DOMAIN&${domainUrl}&ORGID&${orgId}&ORGLOGIN&`;
};

//DOMAIN ID
export const DOMAIN_ID = () => {
  let domainId = localStorage.getItem("DomainId");
  return Number(domainId);
};
