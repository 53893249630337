import React, { useEffect } from 'react'
import { Form, DatePicker, Select, Radio, Checkbox, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'

const { Option } = Select

// Define options for days of the week
export const daysOfWeekOptions = [
	{ label: 'Monday', value: 'MON' },
	{ label: 'Tuesday', value: 'TUE' },
	{ label: 'Wednesday', value: 'WED' },
	{ label: 'Thursday', value: 'THU' },
	{ label: 'Friday', value: 'FRI' },
	{ label: 'Saturday', value: 'SAT' },
	{ label: 'Sunday', value: 'SUN' },
]

const TimeBoundAccessFields = ({
	form,
	accessType,
	setAccessType,
	filters,
	setFilters,
}) => {
	// Set default value for 'isTimeBound' to false on component mount
	useEffect(() => {
		const currentIsTimeBound = form.getFieldValue('isTimeBound')
		if (currentIsTimeBound === undefined) {
			form.setFieldsValue({ isTimeBound: false })
			setAccessType(null)
		}
	}, [form, setAccessType])

	// Handle form field changes to update the central filters state
	const handleValuesChange = (changedValues, allValues) => {
		setFilters(allValues)
	}

	return (
		<>
			<Form.Item
				name="isTimeBound"
				label={
					<span>
						Is this policy Timebound?{' '}
						<Tooltip title="Select if the policy is timebound or not.">
							<QuestionCircleOutlined />
						</Tooltip>
					</span>
				}
				rules={[
					{
						required: true,
						message: 'Please select if policy is timebound',
					},
				]}
				initialValue={false} // Default to 'No'
			>
				<Radio.Group
					onChange={(e) => {
						const value = e.target.value
						if (!value) {
							// Reset dependent fields when 'No' is selected
							form.resetFields([
								'accessType',
								'startDate',
								'startTime',
								'endDate',
								'endTime',
								'timeZone',
								'daysOfWeek',
							])
							setAccessType(null)
						}
						// Update central filters
						setFilters({ ...filters, isTimeBound: value })
					}}
				>
					<Radio value={true}>Yes</Radio>
					<Radio value={false}>No</Radio>
				</Radio.Group>
			</Form.Item>

			{form.getFieldValue('isTimeBound') && (
				<>
					<Form.Item
						name="accessType"
						label={
							<span>
								What type of access do they need?{' '}
								<Tooltip title="Choose whether the access is a one-time event or occurs regularly.">
									<QuestionCircleOutlined />
								</Tooltip>
							</span>
						}
						rules={[
							{
								required: true,
								message: 'Please select an access type',
							},
						]}
					>
						<Select
							placeholder="Select Access Type"
							onChange={(value) => {
								setAccessType(value)
								// Reset dependent fields when accessType changes
								form.resetFields([
									'startDate',
									'startTime',
									'endDate',
									'endTime',
									'timeZone',
									'daysOfWeek',
								])
								// Update central filters
								setFilters({ ...filters, accessType: value })
							}}
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().includes(input.toLowerCase())
							}
						>
							<Option value="One Time">One Time</Option>
							<Option value="Recurring">Recurring</Option>
						</Select>
					</Form.Item>

					{accessType === 'One Time' && (
						<>
							<Form.Item
								label={
									<span>
										Start Date{' '}
										<Tooltip title="Select the start date for access.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								name="startDate"
								rules={[
									{
										required: true,
										message: 'Please select a start date',
									},
								]}
							>
								<DatePicker
									style={{ width: '100%' }}
									format="YYYY-MM-DD"
									placeholder="Select Start Date"
									onChange={(date) => {
										setFilters({ ...filters, startDate: date })
									}}
								/>
							</Form.Item>

							<Form.Item
								label={
									<span>
										Start Time{' '}
										<Tooltip title="Select the start time for access.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								name="startTime"
								rules={[
									{
										required: true,
										message: 'Please select a start time',
									},
								]}
							>
								<DatePicker
									showTime
									format="h:mm a"
									placeholder="Select Start Time"
									style={{ width: '100%' }}
									onChange={(time) => {
										setFilters({ ...filters, startTime: time })
									}}
								/>
							</Form.Item>

							<Form.Item
								label={
									<span>
										End Date{' '}
										<Tooltip title="Select the end date for access. Must be after the start date.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								name="endDate"
								dependencies={['startDate']}
								rules={[
									{
										required: true,
										message: 'Please select an end date',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											const startDate = getFieldValue('startDate')
											if (
												!value ||
												!startDate ||
												value.isAfter(startDate, 'day')
											) {
												return Promise.resolve()
											}
											return Promise.reject(
												new Error('End date must be after start date')
											)
										},
									}),
								]}
							>
								<DatePicker
									style={{ width: '100%' }}
									format="YYYY-MM-DD"
									placeholder="Select End Date"
									onChange={(date) => {
										setFilters({ ...filters, endDate: date })
									}}
								/>
							</Form.Item>

							<Form.Item
								label={
									<span>
										End Time{' '}
										<Tooltip title="Select the end time for access. Must be after the start time.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								name="endTime"
								dependencies={['startTime']}
								rules={[
									{
										required: true,
										message: 'Please select an end time',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											const startTime = getFieldValue('startTime')
											if (!value || !startTime || value.isAfter(startTime)) {
												return Promise.resolve()
											}
											return Promise.reject(
												new Error('End time must be after start time')
											)
										},
									}),
								]}
							>
								<DatePicker
									showTime
									format="h:mm a"
									placeholder="Select End Time"
									style={{ width: '100%' }}
									onChange={(time) => {
										setFilters({ ...filters, endTime: time })
									}}
								/>
							</Form.Item>

							<Form.Item
								label={
									<span>
										Time Zone{' '}
										<Tooltip title="Select the time zone for the specified times.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								name="timeZone"
								rules={[
									{
										required: true,
										message: 'Please select a time zone',
									},
								]}
								initialValue={moment.tz.guess()}
							>
								<Select
									placeholder="Select Time Zone"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									onChange={(value) => {
										setFilters({ ...filters, timeZone: value })
									}}
								>
									{moment.tz.names().map((tz) => {
										const offset = moment.tz(tz).format('Z')
										return (
											<Option key={tz} value={tz}>
												{`${tz} (GMT${offset})`}
											</Option>
										)
									})}
								</Select>
							</Form.Item>
						</>
					)}

					{accessType === 'Recurring' && (
						<>
							<Form.Item
								name="daysOfWeek"
								label={
									<span>
										Days of the Week{' '}
										<Tooltip title="Select the days when access is permitted.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								rules={[
									{
										required: true,
										message: 'Please select at least one day of the week',
									},
								]}
							>
								<Checkbox.Group
									options={daysOfWeekOptions}
									onChange={(checkedValues) => {
										setFilters({ ...filters, daysOfWeek: checkedValues })
									}}
								/>
							</Form.Item>

							<Form.Item
								name="startTimeRecurring"
								label={
									<span>
										Start Time{' '}
										<Tooltip title="Select the start time for access each day.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								rules={[
									{
										required: true,
										message: 'Please select a start time',
									},
								]}
							>
								<DatePicker
									showTime
									format="h:mm a"
									placeholder="Select Start Time"
									style={{ width: '100%' }}
									onChange={(time) => {
										setFilters({ ...filters, startTimeRecurring: time })
									}}
								/>
							</Form.Item>

							<Form.Item
								name="endTimeRecurring"
								label={
									<span>
										End Time{' '}
										<Tooltip title="Select the end time for access each day. Must be after the start time.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								dependencies={['startTimeRecurring']}
								rules={[
									{
										required: true,
										message: 'Please select an end time',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											const startTime = getFieldValue('startTimeRecurring')
											if (!value || !startTime || value.isAfter(startTime)) {
												return Promise.resolve()
											}
											return Promise.reject(
												new Error('End time must be after start time')
											)
										},
									}),
								]}
							>
								<DatePicker
									showTime
									format="h:mm a"
									placeholder="Select End Time"
									style={{ width: '100%' }}
									onChange={(time) => {
										setFilters({ ...filters, endTimeRecurring: time })
									}}
								/>
							</Form.Item>

							<Form.Item
								label={
									<span>
										Time Zone{' '}
										<Tooltip title="Select the time zone for the specified times.">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								}
								name="timeZoneRecurring"
								rules={[
									{
										required: true,
										message: 'Please select a time zone',
									},
								]}
								initialValue={moment.tz.guess()}
							>
								<Select
									placeholder="Select Time Zone"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									onChange={(value) => {
										setFilters({ ...filters, timeZoneRecurring: value })
									}}
								>
									{moment.tz.names().map((tz) => {
										const offset = moment.tz(tz).format('Z')
										return (
											<Option key={tz} value={tz}>
												{`${tz} (GMT${offset})`}
											</Option>
										)
									})}
								</Select>
							</Form.Item>
						</>
					)}
				</>
			)}
		</>
	)
}

export default TimeBoundAccessFields
