import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Typography, Tooltip, message, Card, Switch, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  AUTH_TOKEN,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API,
  REACT_APP_PAM_API, // if you need the RADIUS endpoints from the PAM side
} from "../../../constants";

const { Text } = Typography;
const { Option } = Select;

/**
 * A small helper component to render label+tooltip+switch consistently.
 */
const ToggleField = ({ label, tooltipTitle, name, checked, onChange }) => (
  <Form.Item>
    <Row align="middle" justify="space-between" style={{ width: "100%" }}>
      <Col>
        <p style={{ fontWeight: "500" }}>
          {label}{" "}
          {tooltipTitle && (
            <Tooltip title={tooltipTitle}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </p>
      </Col>
      <Col>
        <Form.Item name={name} valuePropName="checked" noStyle>
          <Switch checked={checked} onChange={onChange} />
        </Form.Item>
      </Col>
    </Row>
  </Form.Item>
);

const JustInTimeWorkflow = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [notification, notificationHolder] = message.useMessage();

  // JIT toggle (bind it to the Switch)
  const [isJustInTimeActive, setIsJustInTimeActive] = useState(false);

  // Basic policy info from local storage
  const [basicPolicyData, setBasicPolicyData] = useState(null);

  // Fetched data for "shadow" fields
  const [allEndpoints, setAllEndpoints] = useState([]); // for endpoints policy
  const [allAdGroups, setAllAdGroups] = useState([]); // for AD policy
  const [allNetworkDevices, setAllNetworkDevices] = useState([]); // for radius policy

  const searchParams = new URLSearchParams(window.location.search);
  const isEditMode = searchParams.get("edit") === "true";

  /**
   * 1. On mount, read localStorage and parse it once.
   * 2. Then, based on policyType, we fetch additional data:
   *    - endpoints (for "endpoints" policy)
   *    - AD groups (for "AD" policy)
   *    - network devices (for "radius" policy)
   * 3. Also prefill form fields for read-only selections.
   */
  useEffect(() => {
    const storedData = localStorage.getItem("policyDraft.basicPolicyInformation");
    const extraData = localStorage.getItem("ExtraData");
    if (!storedData) return;

    const parsed = JSON.parse(storedData);

    setBasicPolicyData(parsed);

    // Dynamically handle each policy type
    if (parsed.policyType === "endpoints") {
      // 1) parse group IDs from endpointGroupsRaw
      const groupIds = parseGroupIds(JSON.parse(extraData) || []);
      if (groupIds.length > 0) {
        fetchEndpoints(groupIds);
      }

      // 2) set read-only endpoints in the form
      const selectedEndpoints = parsed?.policyJson?.endpoints?.groups
        ? Object.values(parsed.policyJson.endpoints.groups).flat()
        : [];
      form.setFieldsValue({
        selectedEndpoints, // read-only
      });
    } else if (parsed.policyType === "AD") {
      // 1) fetch all AD groups
      fetchADGroups();
      // 2) set read-only AD groups in the form
      const selectedAdGroups = parsed?.policyJson?.ad?.groups || [];
      form.setFieldsValue({
        selectedAdGroups, // read-only
      });
    } else if (parsed.policyType === "radius") {
      // 1) fetch all network devices
      fetchNetworkDevices();
      // 2) set read-only devices in the form
      //    (assuming the user previously selected them in policyJson.networks.endpoints)
      const selectedNetworkEndpoints = parsed?.policyJson?.networks?.endpoints || [];
      form.setFieldsValue({
        selectedNetworkEndpoints, // read-only
      });
    }
    // If you have "ServiceAccount", you'd replicate a similar approach
  }, []);

  /**
   * Helper to parse group IDs from strings like ["123|Marketing", "456|Engineering"]
   */
  const parseGroupIds = (rawGroupStrings) => {
    return rawGroupStrings
      .map((str) => {
        const parts = str.split("|");
        return parseInt(parts[0], 10);
      })
      .filter((id) => !isNaN(id));
  };

  /**
   * Fetch endpoints for "endpoints" policy
   */
  const fetchEndpoints = async (groupIds) => {
    try {
      const payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
        group_ids: groupIds,
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/GetMachinesByGroupID`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const endpoints = (res?.data?.machines || []).map((m) => m.hostname);
      setAllEndpoints(endpoints);
    } catch (error) {
      notification.error("Failed to fetch endpoints: " + (error.message || "Unknown error"));
    }
  };

  /**
   * Fetch AD groups for "AD" policy
   */
  const fetchADGroups = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        pageSize: 100,
      };
      const res = await axios.post(`${REACT_APP_API}/ad/ListAdGroups`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const groups = (res?.data?.groups || []).map((group) => ({
        label: group.groupName,
        value: group.groupName,
      }));
      setAllAdGroups(groups);
    } catch (error) {
      console.error("Error fetching AD groups:", error);
      notification.error("Failed to fetch AD groups for JIT policy.");
    }
  };

  /**
   * Fetch network devices for "radius" policy
   * (Example uses REACT_APP_PAM_API's /instances/list if that's your real endpoint)
   */
  const fetchNetworkDevices = async () => {
    try {
      const payload = {
        OrgID: ORG_ID(),
        DomainID: DOMAIN_ID(),
        Search: "",
        Filter: { FilterBy: "", Value: "" },
        PageSize: 100,
        PageID: 1,
        MachineType: "radius",
      };
      const res = await axios.post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const devices = (res?.data?.instances || []).map((inst) => {
        // store any field you need to identify the device
        return inst.publicIp || "";
      });
      setAllNetworkDevices(devices);
    } catch (error) {
      console.error("Error fetching network devices:", error);
      notification.error("Failed to fetch network devices for JIT policy.");
    }
  };

  /**
   * Handler for "Save and Next" or "Update and Next"
   */
  const handleSaveWorkflow = async () => {
    try {
      await form.validateFields();
      notification.success("Just in Time workflow settings saved successfully!");
      onNavigateNext();
    } catch (error) {
      notification.error("Please fix validation errors before saving.");
    }
  };

  /**
   * Handler for "Create Policy" button
   */
  const handleCreatePolicy = () => {
    // If you want to incorporate the "shadow" fields into your final policy,
    // you might do something like form.getFieldsValue() here, then merge it
    // into an existing payload, etc.
    onCreatePolicy(/* pass your final or partial JIT data */);
  };

  /**
   * Cancel & reset
   */
  const handleCancel = () => {
    form.resetFields();
    setIsJustInTimeActive(false);
    localStorage.removeItem("policyDraft.basicPolicyInformation");
    localStorage.removeItem("formSessionData");
    localStorage.removeItem("editPolicyData");
    navigate("/pam/policy/v2");
  };

  /** RENDERING **/

  // Endpoints policy JIT
  const renderEndpointJIT = () => {
    return (
      <>
        <Form.Item
          label={
            <span>
              Selected Endpoints{" "}
              <Tooltip title="These endpoints are set in Basic Policy Information and cannot be changed here.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="selectedEndpoints"
        >
          <Select mode="multiple" style={{ width: "100%" }} disabled />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Shadow Endpoints{" "}
              <Tooltip title="Select endpoints that should be associated with Just in Time shadow groups.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="shadowEndpoints"
          rules={[
            {
              required: isJustInTimeActive,
              message: "Please select at least one shadow endpoint (or disable JIT).",
            },
          ]}
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select shadow endpoints"
            disabled={!isJustInTimeActive}
          >
            {allEndpoints.map((ep) => (
              <Option key={`shadow-${ep}`} value={`shadow-${ep}`}>
                {`shadow-${ep}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
    );
  };

  // AD policy JIT
  const renderADJIT = () => {
    return (
      <>
        <Form.Item
          label={
            <span>
              Selected AD Groups{" "}
              <Tooltip title="These AD groups are set in Basic Policy Information and cannot be changed here.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="selectedAdGroups"
        >
          <Select mode="multiple" style={{ width: "100%" }} disabled />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Shadow AD Groups{" "}
              <Tooltip title="Select the AD groups to apply JIT via 'shadow' groups.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="shadowAdGroups"
          rules={[
            {
              required: isJustInTimeActive,
              message: "Please select at least one shadow AD group (or disable JIT).",
            },
          ]}
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select shadow AD groups"
            disabled={!isJustInTimeActive}
          >
            {allAdGroups.map((grp) => (
              <Option key={`shadow-${grp.value}`} value={`shadow-${grp.value}`}>
                {`shadow-${grp.label}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
    );
  };

  // Network/Radius policy JIT
  const renderNetworkJIT = () => {
    return (
      <>
        <Form.Item
          label={
            <span>
              Selected Network Endpoints{" "}
              <Tooltip title="These network endpoints are set in Basic Policy Information and cannot be changed here.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="selectedNetworkEndpoints"
        >
          <Select mode="multiple" style={{ width: "100%" }} disabled />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Shadow Network Endpoints{" "}
              <Tooltip title="Select network endpoints that should be associated with Just in Time shadow groups.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="shadowNetworkEndpoints"
          rules={[
            {
              required: isJustInTimeActive,
              message: "Please select at least one device (or disable JIT).",
            },
          ]}
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select shadow network endpoints"
            disabled={!isJustInTimeActive}
          >
            {allNetworkDevices.map((dev) => (
              <Option key={`shadow-${dev}`} value={`shadow-${dev}`}>
                {`shadow-${dev}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
    );
  };

  return (
    <div>
      {notificationHolder}
      <Card>
        <Form form={form} layout="vertical">
          {/* Toggle: Activate Just in Time */}
          <ToggleField
            label="Activate Just in Time Policy"
            tooltipTitle="Toggle to enable or disable Just in Time policy activation."
            name="isJustInTimeActive"
            checked={isJustInTimeActive}
            onChange={setIsJustInTimeActive}
          />

          {basicPolicyData?.policyType === "endpoints" && renderEndpointJIT()}
          {basicPolicyData?.policyType === "AD" && renderADJIT()}
          {basicPolicyData?.policyType === "radius" && renderNetworkJIT()}
          {/* If "ServiceAccount", replicate the same idea in a new function. */}

          <Row>
            <Col>
              <Text type="secondary">
                All these fields are optional and managed by Authnull agent.
              </Text>
            </Col>
          </Row>
        </Form>
      </Card>

      <Row justify="end" style={{ marginTop: "24px" }}>
        <Col>
          <Button onClick={handleCancel} style={{ marginRight: "16px" }}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSaveWorkflow} style={{ marginRight: "16px" }}>
            {isEditMode ? "Update and Next" : "Save and Next"}
          </Button>
          <Button type="primary" onClick={handleCreatePolicy} loading={loadingPolicy}>
            {isEditMode ? "Update Policy" : "Create Policy"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default JustInTimeWorkflow;
