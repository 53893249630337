import React from 'react'
import { Form, Row, Col, Select, InputNumber, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Option } = Select

const comparatorOptions = [
	{ label: 'Greater Than', value: '>' },
	{ label: 'Less Than', value: '<' },
	{ label: 'Equals', value: '==' },
	{ label: 'Greater or Equals', value: '>=' },
	{ label: 'Less or Equals', value: '<=' },
]

const IdentityRiskFilterFields = ({ form, filters, setFilters }) => {
	return (
		<>
			{/* Persona Value */}
			<Form.Item
				label={
					<span>
						Persona Value{' '}
						<Tooltip title="Filter policies based on persona value using a comparator and value.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['identityRisk', 'persona']}
				rules={[
					{
						validator: (_, value) => {
							if (!value) return Promise.resolve()
							const { comparator, value: val } = value
							if (!comparator || val === undefined) {
								return Promise.reject(
									new Error(
										'Both comparator and value are required for Persona Value.'
									)
								)
							}
							return Promise.resolve()
						},
					},
				]}
			>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item name={['identityRisk', 'persona', 'comparator']} noStyle>
							<Select
								placeholder="Comparator"
								allowClear
								style={{ width: '100%' }}
								onChange={(value) => {
									setFilters({
										...filters,
										identityRisk: {
											...filters.identityRisk,
											persona: {
												...filters.identityRisk?.persona,
												comparator: value,
												// Reset value if comparator changes
												value:
													value !== undefined
														? filters.identityRisk?.persona?.value
														: undefined,
											},
										},
									})
								}}
							>
								{comparatorOptions.map((comp) => (
									<Option key={comp.value} value={comp.value}>
										{comp.label}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name={['identityRisk', 'persona', 'value']} noStyle>
							<InputNumber
								style={{ width: '100%' }}
								placeholder="Value"
								min={0}
								max={10}
								step={1}
								onChange={(value) => {
									setFilters({
										...filters,
										identityRisk: {
											...filters.identityRisk,
											persona: {
												...filters.identityRisk?.persona,
												value: value,
											},
										},
									})
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form.Item>

			{/* Confidence Score */}
			<Form.Item
				label={
					<span>
						Confidence Score{' '}
						<Tooltip title="Filter policies based on confidence score using a comparator and value.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['identityRisk', 'confidence']}
				rules={[
					{
						validator: (_, value) => {
							if (!value) return Promise.resolve()
							const { comparator, value: val } = value
							if (!comparator || val === undefined) {
								return Promise.reject(
									new Error(
										'Both comparator and value are required for Confidence Score.'
									)
								)
							}
							return Promise.resolve()
						},
					},
				]}
			>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item
							name={['identityRisk', 'confidence', 'comparator']}
							noStyle
						>
							<Select
								placeholder="Comparator"
								allowClear
								style={{ width: '100%' }}
								onChange={(value) => {
									setFilters({
										...filters,
										identityRisk: {
											...filters.identityRisk,
											confidence: {
												...filters.identityRisk?.confidence,
												comparator: value,
												// Reset value if comparator changes
												value:
													value !== undefined
														? filters.identityRisk?.confidence?.value
														: undefined,
											},
										},
									})
								}}
							>
								{comparatorOptions.map((comp) => (
									<Option key={comp.value} value={comp.value}>
										{comp.label}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name={['identityRisk', 'confidence', 'value']} noStyle>
							<InputNumber
								style={{ width: '100%' }}
								placeholder="Value"
								min={0}
								max={10}
								step={1}
								onChange={(value) => {
									setFilters({
										...filters,
										identityRisk: {
											...filters.identityRisk,
											confidence: {
												...filters.identityRisk?.confidence,
												value: value,
											},
										},
									})
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form.Item>

			{/* Risk Score */}
			<Form.Item
				label={
					<span>
						Risk Score{' '}
						<Tooltip title="Filter policies based on risk score using a comparator and value.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['identityRisk', 'risk']}
				rules={[
					{
						validator: (_, value) => {
							if (!value) return Promise.resolve()
							const { comparator, value: val } = value
							if (!comparator || val === undefined) {
								return Promise.reject(
									new Error(
										'Both comparator and value are required for Risk Score.'
									)
								)
							}
							return Promise.resolve()
						},
					},
				]}
			>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item name={['identityRisk', 'risk', 'comparator']} noStyle>
							<Select
								placeholder="Comparator"
								allowClear
								style={{ width: '100%' }}
								onChange={(value) => {
									setFilters({
										...filters,
										identityRisk: {
											...filters.identityRisk,
											risk: {
												...filters.identityRisk?.risk,
												comparator: value,
												// Reset value if comparator changes
												value:
													value !== undefined
														? filters.identityRisk?.risk?.value
														: undefined,
											},
										},
									})
								}}
							>
								{comparatorOptions.map((comp) => (
									<Option key={comp.value} value={comp.value}>
										{comp.label}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name={['identityRisk', 'risk', 'value']} noStyle>
							<InputNumber
								style={{ width: '100%' }}
								placeholder="Value"
								min={0}
								max={10}
								step={1}
								onChange={(value) => {
									setFilters({
										...filters,
										identityRisk: {
											...filters.identityRisk,
											risk: {
												...filters.identityRisk?.risk,
												value: value,
											},
										},
									})
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form.Item>
		</>
	)
}

export default IdentityRiskFilterFields
