import React, { useState, useEffect } from 'react'
import {
	Card,
	Form,
	Input,
	Button,
	Row,
	Col,
	Table,
	Tag,
	message,
	Modal,
	Typography,
} from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Text } = Typography

const getLocalStorageData = (key) => {
	try {
		const data = localStorage.getItem(key)
		return data ? JSON.parse(data) : null
	} catch (error) {
		console.error(`Error parsing localStorage key "${key}":`, error)
		return null
	}
}

const setLocalStorageData = (key, value) => {
	try {
		localStorage.setItem(key, JSON.stringify(value))
	} catch (error) {
		console.error(`Error setting localStorage key "${key}":`, error)
	}
}

const NetworkAccess = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
	const searchParams = new URLSearchParams(window.location.search)
	const [form] = Form.useForm()
	const [networks, setNetworks] = useState([])
	const [editingKey, setEditingKey] = useState(null)
	const [notification, notificationHolder] = message.useMessage()
	const navigate = useNavigate()
	const [policySaved, setPolicySaved] = useState(false)
	const [payload, setPayload] = useState()
	const [loading, setLoading] = useState(false)
	const isEditMode = searchParams.get('edit') === 'true'

	useEffect(() => {
		const basicPolicyData = getLocalStorageData(
			'policyDraft.basicPolicyInformation'
		)

		if (basicPolicyData) {
			const existingNetworks =
				basicPolicyData.policyJson?.dit?.geolocation?.ip_ranges || []
			const parsedNetworks = existingNetworks.map((net, index) => ({
				key: index,
				lowerIP: net.start,
				upperIP: net.end,
			}))
			setNetworks(parsedNetworks)
		} else {
			notification.error({
				content:
					'Basic Policy Information is missing. Please complete it first!',
			})
		}
	}, [notification])

	const addOrEditNetwork = (values) => {
		const { lowerIP, upperIP } = values

		const normalizeIP = (ip) => ip.trim()

		if (lowerIP && upperIP) {
			const isDuplicate = networks.some((net) => {
				return (
					normalizeIP(net.lowerIP) === normalizeIP(lowerIP) &&
					normalizeIP(net.upperIP) === normalizeIP(upperIP)
				)
			})

			if (isDuplicate && editingKey === null) {
				notification.warning({
					content: 'This network range already exists.',
				})
				return
			}

			if (isDuplicate && editingKey !== null) {
				const duplicateExcludingCurrent = networks.some((net) => {
					return (
						net.key !== editingKey &&
						normalizeIP(net.lowerIP) === normalizeIP(lowerIP) &&
						normalizeIP(net.upperIP) === normalizeIP(upperIP)
					)
				})
				if (duplicateExcludingCurrent) {
					notification.warning({
						content: 'Another network with the same IP range already exists.',
					})
					return
				}
			}
		}

		if (editingKey !== null) {
			setNetworks((prev) =>
				prev.map((net) =>
					net.key === editingKey ? { ...net, ...values } : net
				)
			)
			notification.success({
				content: 'Network updated successfully!',
			})
		} else {
			const newNetwork = {
				key: Date.now(),
				...values,
			}
			setNetworks([...networks, newNetwork])
			notification.success({
				content: 'Network added successfully!',
			})
		}
		form.resetFields()
		setEditingKey(null)
	}

	const editNetwork = (record) => {
		setEditingKey(record.key)
		form.setFieldsValue(record)
	}

	const removeNetwork = (key) => {
		Modal.confirm({
			title: 'Are you sure you want to remove this network?',
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk: () => {
				setNetworks((prev) => prev.filter((net) => net.key !== key))
				notification.info({
					content: 'Network removed.',
				})
			},
		})
	}

	const handleSavePolicy = async () => {
		try {
			setLoading(true)

			if (networks.length === 0) {
				notification.error({
					content: 'No networks to save.',
				})
				return null
			}

			const networkRanges = networks.map((net) => ({
				start: net.lowerIP,
				end: net.upperIP,
			}))

			const basicPolicyDraft = getLocalStorageData(
				'policyDraft.basicPolicyInformation'
			)
			if (!basicPolicyDraft) {
				notification.error({
					content:
						'Basic Policy Information is missing. Please complete it first!',
				})
				return null
			}

			const basicPolicyData = { ...basicPolicyDraft }
			const policyJson = { ...basicPolicyData.policyJson }
			const dit = { ...policyJson.dit }

			dit.geolocation = {
				...dit.geolocation,
				ip_ranges: networkRanges,
			}

			policyJson.dit = dit

			const updatedBasicPolicyData = {
				...basicPolicyData,
				policyJson: policyJson,
			}

			setLocalStorageData(
				'policyDraft.basicPolicyInformation',
				updatedBasicPolicyData
			)

			setPayload(updatedBasicPolicyData)
			setPolicySaved(true)
			return updatedBasicPolicyData
		} catch (error) {
			console.error('Failed to save policy:', error)
			message.error('Failed to save policy.')
			return null
		} finally {
			setLoading(false)
		}
	}

	const handleSaveAndNext = async () => {
		const updatedData = await handleSavePolicy()
		if (updatedData) {
			onNavigateNext()
		}
	}

	const handleSaveAndCreate = async () => {
		const updatedData = await handleSavePolicy()
		if (updatedData) {
			onCreatePolicy({
				orgId: updatedData.orgId,
				tenantId: updatedData.tenantId,
				policyName: updatedData.policyName,
				policyType: updatedData.policyType,
				policyJson: updatedData.policyJson,
			})
		}
	}

	const handleSaveOnly = async () => {
		await handleSavePolicy()
	}

	const handleCancel = () => {
		Modal.confirm({
			title: 'Are you sure you want to cancel?',
			content: 'All unsaved changes will be lost.',
			onOk: () => {
				localStorage.removeItem('policyDraft.basicPolicyInformation')
				localStorage.removeItem('formSessionData')
				localStorage.removeItem('editPolicyData')
				navigate('/pam/policy/v2')
			},
		})
	}

	const columns = [
		{
			title: 'Lower IP',
			dataIndex: 'lowerIP',
			key: 'lowerIP',
			render: (text) => <Tag color="blue">{text}</Tag>,
		},
		{
			title: 'Upper IP',
			dataIndex: 'upperIP',
			key: 'upperIP',
			render: (text) => <Tag color="blue">{text}</Tag>,
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => (
				<>
					<Button
						type="link"
						icon={<EditOutlined />}
						onClick={() => editNetwork(record)}
					/>
					<Button
						type="link"
						danger
						icon={<DeleteOutlined />}
						onClick={() => removeNetwork(record.key)}
					/>
				</>
			),
		},
	]

	const dataSource = networks.map((network) => ({
		key: network.key,
		lowerIP: network.lowerIP,
		upperIP: network.upperIP,
	}))

	return (
		<div>
			{notificationHolder}
			<Card>
				<Form form={form} layout="vertical" onFinish={addOrEditNetwork}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="lowerIP"
								label="Lower IP"
								rules={[
									{
										required: true,
										message: 'Please enter the lower IP address.',
									},
									{
										pattern:
											/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}$/,
										message: 'Please enter a valid IP address.',
									},
								]}
							>
								<Input placeholder="e.g., 192.168.1.1" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="upperIP"
								label="Upper IP"
								dependencies={['lowerIP']}
								rules={[
									{
										required: true,
										message: 'Please enter the upper IP address.',
									},
									{
										pattern:
											/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}$/,
										message: 'Please enter a valid IP address.',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											const lowerIP = getFieldValue('lowerIP')
											if (!value || !lowerIP) {
												return Promise.resolve()
											}
											const ipToNumber = (ip) =>
												ip
													.split('.')
													.reduce(
														(acc, octet) => acc * 256 + parseInt(octet, 10),
														0
													)
											if (ipToNumber(value) >= ipToNumber(lowerIP)) {
												return Promise.resolve()
											}
											return Promise.reject(
												new Error(
													'Upper IP must be greater than or equal to Lower IP.'
												)
											)
										},
									}),
								]}
							>
								<Input placeholder="e.g., 192.168.1.255" />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="end">
						<Button
							type="primary"
							htmlType="submit"
							icon={editingKey !== null ? <EditOutlined /> : <PlusOutlined />}
						>
							{editingKey !== null ? 'Update Network' : 'Add Network'}
						</Button>
					</Row>
				</Form>
				<Row>
					<Col style={{ marginTop: '10px' }}>
						<Text type="secondary">
							"All these fields are optional and managed by Authnull agent".
						</Text>
					</Col>
				</Row>
			</Card>
			{networks.length > 0 && (
				<>
					<Card style={{ marginBottom: '24px', marginTop: '24px' }}>
						<Table
							dataSource={dataSource}
							columns={columns}
							rowKey="key"
							style={{ marginTop: 16 }}
							pagination={false}
						/>
						{isEditMode && (
							<Row>
								<Col style={{ marginTop: '10px' }}>
									<Text type="secondary">
										"Dont keep this policy field empty"
									</Text>
								</Col>
							</Row>
						)}
					</Card>
					<Row justify="end" style={{ marginTop: '24px' }}>
						<Col>
							<Button onClick={handleCancel} style={{ marginRight: '16px' }}>
								Cancel
							</Button>
							<Button
								type="primary"
								onClick={handleSaveAndNext}
								style={{ marginRight: '16px' }}
							>
								{isEditMode ? 'Update and Next' : 'Save and Next'}
							</Button>
							<Button
								type="primary"
								onClick={handleSaveAndCreate}
								loading={loadingPolicy}
							>
								{isEditMode ? 'Update Policy' : 'Create Policy'}
							</Button>
						</Col>
					</Row>
				</>
			)}
		</div>
	)
}

export default NetworkAccess
