// src/components/AdvancedFilterDrawer/filters/LocationAccessFields.js

import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import countriesList from '../../ditConditions/countries'

const { Option } = Select

const LocationAccessFields = ({ form, filters, setFilters }) => {
	return (
		<>
			<Form.Item
				name={['locationAccess', 'allowedLocations']}
				label={
					<span>
						Allowed Countries{' '}
						<Tooltip title="Select the countries where access is permitted.">
							<QuestionCircleOutlined />
						</Tooltip>
					</span>
				}
				rules={[
					{
						required: true,
						message: 'Please select at least one country.',
					},
				]}
			>
				<Select
					mode="multiple"
					placeholder="Select countries"
					allowClear
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={(value) => {
						setFilters({
							...filters,
							locationAccess: {
								...filters.locationAccess,
								allowedLocations: value,
							},
						})
					}}
				>
					{countriesList.map((country) => (
						<Option key={country.code} value={country.code}>
							{country.name}
						</Option>
					))}
				</Select>
			</Form.Item>
		</>
	)
}

export default LocationAccessFields
