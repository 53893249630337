import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Tag,
  message,
  Drawer,
  Tooltip,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API } from "../../constants";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { EditOutlined, InboxOutlined, InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";
import ReactJson from "@microlink/react-json-view";
import AIAgent from "./AIAgent/AIAgent";
import AdvancedFilters from "./advancedFilters/advancedFilters";
import PolicyImpact from "./PolicyImpact";
import DitPolicyViewer from "./ditPolicyViewer";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const statusOptions = [
  { label: "Approved", value: "Approved" },
  { label: "Pending", value: "Pending" },
];

const policyTypesOptions = [
  { label: "Active Directory", value: "AD" },
  { label: "Service Account", value: "ServiceAccount" },
  { label: "Endpoints", value: "endpoints" },
  { label: "Radius", value: "radius" },
];

const JsonCellRenderer = ({ value }) => (
  <ReactJson
    src={value}
    name={false}
    collapsed={false}
    theme={"rjv-default"}
    enableClipboard={false}
    enableEdit={false}
    enableAdd={false}
    enableDelete={false}
    displayDataTypes={false}
    displayObjectSize={false}
    displayArrayKey={false}
  />
);

export default function Policies() {
  const [notification, notificationHolder] = message.useMessage();
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 500);
  const [viewPolicyDrawer, setViewPolicyDrawer] = useState(false);
  const [policyJsonData, setPolicyJsonData] = useState();
  const [selectedPolicyType, setSelectedPolicyType] = useState("AD");

  const [openApprovePolicyModal, setOpenApprovePolicyModal] = useState(false);
  const [selectedPolicyRow, setSelectedPolicyRow] = useState(null);
  const [openDeletePolicyModal, setOpenDeletePolicyModal] = useState(false);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [showPolicyImpact, setShowPolicyImpact] = useState(false);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    applyFilter("policyType", "AD");
  }, []);

  useEffect(() => {
    fetchPolicyData();
  }, [appliedFilters, debouncedValue, currentPage]);

  const fetchPolicyData = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      requestId: "", // DOUBT: for backend use only.
      page: currentPage + 1,
      limit: currentPageModel.pageSize,
      search: debouncedValue ? debouncedValue : "",
      ...(Object.keys(appliedFilters).length > 0 && {
        filters: Object.entries(appliedFilters).map(([key, value]) => ({
          filterParameter: key,
          filterValue: value,
        })),
      }),
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/ListPolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      setPolicies(res?.data?.data || []);

      setTotalCount(res?.data?.total_pages);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load policies",
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const ADColumnsData = [
    {
      headerName: "AD Groups",
      field: "adGroup",
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.ad?.groups?.map((group, index) => (
            <Tag key={index}>{group}</Tag>
          ))}
        </div>
      ),
    },
    {
      headerName: "AD Users",
      field: "adUsers",
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.ad?.users?.map((user, index) => (
            <Tag key={index}>{user}</Tag>
          ))}
        </div>
      ),
    },
  ];

  const RadiusColumnsData = [
    {
      headerName: "Radius Endpoints",
      field: "radiusEndpoints",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.networks?.endpoints?.map((endpoint, index) => (
            <Tag key={index}>{endpoint}</Tag>
          ))}
        </div>
      ),
    },
  ];

  const EndpointsColumnsData = [
    {
      headerName: "Endpoints Groups",
      field: "endpointGrouups",
      flex: 2,
      renderCell: (params) => (
        <div>
          {Object.keys(params?.row?.policyJson?.endpoints?.groups || {})?.map((group, index) => (
            <Tag key={index}>{group}</Tag>
          ))}
        </div>
      ),
    },
    {
      headerName: "Endpoints",
      field: "endpoints",
      flex: 2,
      renderCell: (params) => (
        <div>
          {Object.values(params?.row?.policyJson?.endpoints?.groups || {})
            .flat()
            .map((endpoint, index) => (
              <Tag color="blue" key={index}>
                {endpoint}
              </Tag>
            ))}
        </div>
      ),
    },
    {
      headerName: "Endpoints Users",
      field: "endpointUsers",
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.endpoints?.users?.map((user, index) => (
            <Tag key={index}>{user}</Tag>
          ))}
        </div>
      ),
    },
  ];

  const ServiceColumnsData = [
    {
      headerName: "Source Endpoint",
      field: "serviceEndpoints",
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.serviceaccount?.source_endpoint?.map((endpoint, index) => (
            <Tag key={index}>{endpoint}</Tag>
          ))}
        </div>
      ),
    },
    {
      headerName: "Destination Endpoint",
      field: "destinationEndpoints",
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.serviceaccount?.destination_endpoint?.map((endpoint, index) => (
            <Tag key={index}>{endpoint}</Tag>
          ))}
        </div>
      ),
    },
    {
      headerName: "Service Account Users",
      field: "serviceAccountUsers",
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.serviceaccount?.users?.map((user, index) => (
            <Tag key={index}>{user}</Tag>
          ))}
        </div>
      ),
    },
  ];

  const columns = [
    {
      headerName: "",
      field: "updatePolicy",
      flex: 0.2,
      renderCell: (val) => (
        <Link
          disabled={val.row.status !== "Approved"}
          to={val.row.status === "Approved" ? "/pam/policy/v2/createPolicy?edit=true" : undefined}
          onClick={() => {
            if (val.row.status === "Approved") {
              localStorage.setItem("editPolicyData", JSON.stringify(val.row));
              localStorage.setItem("storedDataId", val.row.id);
              console.log(val.row);
            }
          }}
        >
          <EditOutlined />
        </Link>
      ),
    },
    {
      headerName: "Policy Name",
      field: "policyName",
      flex: 1.5,
      renderCell: (val) => (
        <a
          onClick={() => {
            setViewPolicyDrawer(true);
            setPolicyJsonData(val?.row?.policyJson);
          }}
        >
          {val?.row?.policyName}
        </a>
      ),
    },
    {
      headerName: "Type",
      field: "policyType",
      flex: 1,
      renderCell: (val) => <Tag>{val?.row?.policyType}</Tag>,
    },
    {
      headerName: "IAM Users",
      field: "iamUsers",
      flex: 2.3,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.permissions?.iam_users?.map((user, index) => (
            <Tag key={index}>{user}</Tag>
          )) || "No users"}
        </div>
      ),
    },
    ...(selectedPolicyType === "AD"
      ? ADColumnsData
      : selectedPolicyType === "radius"
      ? RadiusColumnsData
      : selectedPolicyType === "ServiceAccount"
      ? ServiceColumnsData
      : selectedPolicyType === "endpoints"
      ? EndpointsColumnsData
      : []),
    {
      headerName: "Policy Action",
      field: "allowed",
      flex: 1.2,
      renderCell: (params) => (
        <Tag color={params.row.policyJson?.permissions?.allowed ? "green" : "red"}>
          {params.row.policyJson?.permissions?.allowed ? "Allowed" : "Not Allowed"}
        </Tag>
      ),
    },
    {
      headerName: (
        <div>
          <span style={{ marginRight: ".5rem" }}>Status</span>
          <Tooltip title="Status of the policy can be pending, Active, Inactive, or Deprecated">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      field: "status",
      flex: 1,
      renderCell: (val) => (
        <Tag
          color={
            val?.row?.status === "Approved"
              ? "green"
              : val?.row?.status === "Pending"
              ? "orange"
              : val?.row?.status === "Revoked"
              ? "red"
              : val?.row?.status === "Deprecated"
              ? "gray"
              : "default"
          }
        >
          {val?.row?.status}
        </Tag>
      ),
    },
    {
      headerName: "Updated At",
      field: "updatedAt",
      flex: 2,
      renderCell: (val) =>
        moment(val?.row?.updatedAt).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <a
                  onClick={() => {
                    setViewPolicyDrawer(true);
                    setPolicyJsonData(val?.row?.policyJson);
                  }}
                >
                  Conditional Access
                </a>
              </Menu.Item>

              <Menu.Item key="2">
                <a
                  onClick={() => {
                    setOpenApprovePolicyModal(true);
                    setSelectedPolicyRow(val.row);
                  }}
                >
                  {val?.row?.status === "Approved" ? "Revoke" : "Approve"}
                </a>
              </Menu.Item>

              <Menu.Item key="3">
                <a onClick={() => {}}>History</a>
              </Menu.Item>

              <Menu.Item key="4">
                <a
                  onClick={() => {
                    setSelectedPolicyRow(val.row);
                    setOpenDeletePolicyModal(true);
                  }}
                >
                  Delete
                </a>
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ].filter(Boolean);

  const handleModalClose = () => {
    setSelectedPolicyRow(null);
    setOpenApprovePolicyModal(false);
  };

  const handlePolicyApproval = () => {
    fetchPolicyData();
  };

  const applyFilter = (type, value) => {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(0);
  };

  const getAppliedFilterValue = (type) => {
    return appliedFilters[type] ?? null;
  };

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);

    //console.log("selected row", newRowSelectionModel);
  };

  const handleMultiplePolicyAction = async (policyActionType, policyAction) => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      action: {
        actionType: policyActionType,
        actionIds: rowSelectionModel,
        actionValue: policyAction,
      },
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/PolicyAction`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      notification.open({
        type: "success",
        content: `Policies ${
          policyAction === "Deleted"
            ? "Deleted"
            : policyAction === "Allow"
            ? "Action Allowed"
            : policyAction === "Deny"
            ? "Action Denied"
            : policyAction === "Approved"
            ? "Status Approved"
            : "Status Deactivated"
        } Successfully.`,
      });
      setIsLoading(false);
      fetchPolicyData();
    } catch (error) {
      setIsLoading(false);
      notification.open({
        type: "error",
        content: "Failed to perform policy action.",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("selected rows", rowSelectionModel);
  }, [rowSelectionModel]);

  return (
    <>
      {notificationHolder}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Policies</h2>
            </Col>
            <Col>
              <Row>
                <Col>
                  <AIAgent fetchPolicyData={fetchPolicyData} applyFilter={applyFilter} />
                </Col>
                <Col>
                  <Link to={"/pam/policy/v2/createPolicy"}>
                    <Button type="primary">Add Policy</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>

          {rowSelectionModel.length === 0 ? (
            <>
              {/* FILTERS ROW */}
              <Row style={{ marginBottom: "1rem", marginTop: "1rem" }} gutter={20}>
                <Col span={4}>
                  <p className="search-label">Search</p>
                  <Input
                    type="text"
                    placeholder="Search policy"
                    onChange={(e) => setSearch(e.target.value)}
                    allowClear
                  />
                </Col>
                <Col span={4}>
                  <p className="search-label">Policy Type</p>
                  <Select
                    placeholder="Select policy type"
                    onChange={(val) => {
                      applyFilter("policyType", val);
                      setSelectedPolicyType(val);
                    }}
                    value={getAppliedFilterValue("policyType")}
                  >
                    {policyTypesOptions.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                {/* We only keep "Approved" or "Pending" so we remove references to Suspended/Deleted */}
                <Col span={4}>
                  <p className="search-label">Status</p>
                  <Select
                    placeholder="Select status"
                    onChange={(val) => applyFilter("status", val)}
                    value={getAppliedFilterValue("status")}
                  >
                    {statusOptions.map((status) => (
                      <Select.Option key={status.value} value={status.value}>
                        {status.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col span={4}>
                  <p className="search-label">Generated by</p>
                  <Select
                    placeholder="Select generated by"
                    onChange={(val) => applyFilter("generatedBy", val)}
                    value={getAppliedFilterValue("generatedBy")}
                  >
                    <Select.Option value="Auto">Auto</Select.Option>
                    <Select.Option value="AI">AI</Select.Option>
                    <Select.Option value="Manual">Manual</Select.Option>
                  </Select>
                </Col>

                <Col>
                  <p className="search-label" style={{ marginTop: "35px" }}></p>
                  <AdvancedFilters />
                </Col>
              </Row>

              {/* APPLIED FILTERS (Tags) */}
              {Object.keys(appliedFilters).length > 0 && (
                <Row style={{ marginBottom: "2rem" }}>
                  <Col>
                    <p className="mb-2">
                      Applied Filters:{" "}
                      <Link
                        href="#"
                        onClick={() => setAppliedFilters([])}
                        style={{ marginLeft: "1rem", color: "#1890ff" }}
                      >
                        Clear filters
                      </Link>
                    </p>

                    {Object.entries(appliedFilters).map(([key, value]) => {
                      if (!value) return null;
                      const capitalize = (text) =>
                        text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
                      return (
                        <Tag
                          key={key}
                          closable={true}
                          onClose={() => {
                            setAppliedFilters((prevFilters) => {
                              let temp = { ...prevFilters };
                              delete temp[key];
                              return temp;
                            });
                          }}
                        >
                          {`${capitalize(key)}: ${capitalize(value)}`}
                        </Tag>
                      );
                    })}
                  </Col>
                </Row>
              )}
            </>
          ) : (
            /* If we DO have row selections => Show only two dropdowns.  
               Optionally remove any "deactivate"/"delete" if you want no references to them. */

            <>
              <Row style={{ marginBottom: "2rem" }} gutter={24} align="middle" justify="start">
                <Col span={4}>
                  <p className="search-label">Change Status</p>
                  <Select
                    style={{ width: 170 }}
                    label="Select Status"
                    placeholder="Select Status"
                    onSelect={(value) => {
                      if (value === "activate") {
                        handleMultiplePolicyAction("status", "Approved");
                      } else if (value === "deactivate") {
                        handleMultiplePolicyAction("status", "Suspended");
                      } else if (value === "delete") {
                        handleMultiplePolicyAction("status", "Deleted");
                      }
                    }}
                  >
                    <Select.Option value="activate">Activate</Select.Option>
                    <Select.Option value="deactivate">Deactivate</Select.Option>
                    <Select.Option value="delete">Delete</Select.Option>
                  </Select>
                </Col>
                {/* 2) Select Policy Action => Allow, Deny */}
                {/* <Col>
                <Select
                  style={{ width: 170 }}
                  placeholder="Select Policy Action"
                  onSelect={(value) => {
                    if (value === "allow") {
                      handleMultiplePolicyAction("permissions", "Allow");
                    } else if (value === "deny") {
                      handleMultiplePolicyAction("permissions", "Deny");
                    }
                  }}
                >
                  <Select.Option value="allow">Allow</Select.Option>
                  <Select.Option value="deny">Deny</Select.Option>
                </Select>
              </Col> */}
              </Row>
              <Row style={{ marginBottom: "2rem" }}>
                <Col>
                  <p className="mb-2">
                    Applied Filters:{" "}
                    <Link
                      href="#"
                      onClick={() => setAppliedFilters([])}
                      style={{ marginLeft: "1rem", color: "#1890ff" }}
                    >
                      Clear filters
                    </Link>
                  </p>

                  {Object.entries(appliedFilters).map(([key, value]) => {
                    if (!value) return null;
                    const capitalize = (text) =>
                      text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
                    return (
                      <Tag
                        key={key}
                        closable={true}
                        onClose={() => {
                          setAppliedFilters((prevFilters) => {
                            let temp = { ...prevFilters };
                            delete temp[key];
                            return temp;
                          });
                        }}
                      >
                        {`${capitalize(key)}: ${capitalize(value)}`}
                      </Tag>
                    );
                  })}
                </Col>
              </Row>
            </>
          )}

          <Row>
            <DataGrid
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={handleRowSelectionModelChange}
              // rowSelectionModel={rowSelectionModel}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                noResultsOverlay: CustomNoRowsOverlay,
              }}
              rows={policies}
              rowCount={totalCount}
              columns={columns}
              style={{ border: "none", width: "100%", overflow: "auto" }}
              loading={isLoading}
              page={currentPage}
              paginationMode="server"
              paginationModel={currentPageModel}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              onPaginationModelChange={(params) => {
                setCurrentPage(params.page);
              }}
            />
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Conditional Access"
        onClose={() => setViewPolicyDrawer(false)}
        open={viewPolicyDrawer}
        width={450}
      >
        <DitPolicyViewer data={policyJsonData} />
      </Drawer>

      <ApprovePolicyModal
        isModelOpen={openApprovePolicyModal}
        handleModalClose={handleModalClose}
        onPolicyApproved={handlePolicyApproval}
        selectedPolicyRow={selectedPolicyRow}
      />

      <DeletePolicyModal
        isModelOpen={openDeletePolicyModal}
        handleModalClose={() => {
          setOpenDeletePolicyModal(false);
        }}
        policyId={selectedPolicyRow?.id}
        onPolicyApproved={handlePolicyApproval}
      />

      <PolicyImpact
        isModelOpen={showPolicyImpact}
        handleModalClose={() => {
          setShowPolicyImpact(false);
        }}
      />
    </>
  );
}

const ApprovePolicyModal = ({
  isModelOpen,
  handleModalClose,
  onPolicyApproved,
  selectedPolicyRow,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleApprove = async () => {
    setIsLoading(true);
    const payload = {
      policyId: selectedPolicyRow?.id,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/approvePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy approved successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to approve policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleRevoke = async () => {
    setIsLoading(true);
    const payload = {
      policyId: selectedPolicyRow?.id,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/revokePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy revoked successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to revoke policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={isModelOpen}
      onCancel={handleModalClose}
      title={
        selectedPolicyRow?.status === "Approved" ? "Revoke Policy Credentails" : "Approve Policy"
      }
      okText={selectedPolicyRow?.status === "Approved" ? "Revoke" : "Approve"}
      onOk={selectedPolicyRow?.status === "Approved" ? handleRevoke : handleApprove}
      width={800}
      okButtonProps={{ loading: isLoading }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Policy Name</p>
          <Input value={selectedPolicyRow?.policyName} disabled />
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Policy Type</p>
          <Input value={selectedPolicyRow?.policyType} disabled />
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>IAM Users</p>
          <Select
            value={selectedPolicyRow?.policyJson?.permissions?.iam_users}
            mode="multiple"
            disabled
            style={{ width: "100%" }}
          />
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Status</p>
          <Input value={selectedPolicyRow?.status} disabled />
        </Col>
      </Row>

      <Col>
        <p style={{ marginBottom: "0.3rem" }}>Policy JSON</p>
        <JsonCellRenderer value={selectedPolicyRow?.policyJson} />
      </Col>
    </Modal>
  );
};

const DeletePolicyModal = ({ isModelOpen, handleModalClose, onPolicyApproved, policyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = async () => {
    setIsLoading(true);
    const payload = {
      policyId: policyId,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/deletePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy deleted successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to delete policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={isModelOpen}
      onCancel={handleModalClose}
      title="Delete Policy"
      okText="Delete"
      width={500}
      onOk={handleDelete}
      okButtonProps={{ loading: isLoading }}
    >
      <p>Are you sure you want to delete this policy?</p>
    </Modal>
  );
};
