import styled, { keyframes } from 'styled-components'
import { Button } from 'antd'


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const AnimatedLetter = styled.span`
	opacity: 0;
	animation: ${fadeIn} 0.2s forwards;
	animation-delay: ${({ delay }) => delay}ms;
	display: inline-block;
`

export const AvatarWrapper = styled.div`
	margin: ${({ isBot }) => (isBot ? '0 8px 0 0' : '0 0 0 8px')};
`

export const ChatContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%; /* Full height of the drawer */
	background-color: #f5f5f5;
	position: relative; /* Allows proper positioning of child elements */
	overflow: hidden;
`

export const Dot = styled.div`
	width: 8px;
	height: 8px;
	margin: 0 2px;
	background-color: #333333;
	border-radius: 50%;
	animation: bounce 1.4s infinite both;

	&:nth-child(1) {
		animation-delay: -0.32s;
	}

	&:nth-child(2) {
		animation-delay: -0.16s;
	}

	@keyframes bounce {
		0%,
		80%,
		100% {
			transform: scale(0);
		}
		40% {
			transform: scale(1);
		}
	}
`

export const MessageBubble = styled.div`
	max-width: 70%;
	min-height: 32px;
	padding: 12px 16px;
	border-radius: 16px;
	background: ${({ isBot }) =>
		isBot
			? 'linear-gradient(135deg, #ffffff, #f0f0f0)' /* Bot messages gradient */
			: 'linear-gradient(135deg,#cfeaff,#9cd5ff)'}; /* User messages gradient */
	border: ${({ isBot }) =>
		isBot ? '1px solid #e0e0e0' : 'none'}; /* Light border for bot messages */
	color: ${({ isBot }) =>
		isBot ? '#333333' : '#ffffff'}; /* Text color based on sender */
	font-size: 16px;
	font-family: Roboto, sans-serif;
	position: relative;
	display: flex;
	flex-wrap: wrap;
`

export const MessageWrapper = styled.div`
	display: flex;
	justify-content: ${({ isBot }) => (isBot ? 'flex-start' : 'flex-end')};
	margin-bottom: ${({ isSameSender }) => (isSameSender ? '4px' : '20px')};
`

export const MessagesContainer = styled.div`
	flex: 1; /* Takes up all available space above the options */
	overflow-y: auto; /* Allows scrolling */
	padding: 16px;
	background: #f5f5f5;
`

export const OptionButton = styled(Button)`
	background: transparent;
	border: 2px solid #379fff; /* Blue border matching user message gradient */
	color: #379fff;
	border-radius: 20px;
	font-size: 14px;
	padding: 8px 16px;
	transition: background 0.3s, color 0.3s, transform 0.2s;

	&:hover,
	&:focus {
		background: #379fff;
		color: #ffffff;
		transform: translateY(-2px);
	}
`
export const OptionsContainer = styled.div`
	display: flex;
	justify-content: space-between; /* Distributes buttons evenly */
	align-items: center; /* Vertically aligns buttons in the container */
	flex-wrap: wrap; /* Allows buttons to wrap to the next row if needed */
	gap: 16px; /* Adds space between buttons */
	background: #f5f5f5; /* Background color for the container */
	padding: 20px; /* Padding inside the container */
	border-top: 1px solid #e0e0e0; /* Adds a subtle top border */
	position: relative; /* Keeps it positioned within the parent container */
	z-index: 1000; /* Ensures it stays above other elements */
`
export const Timestamp = styled.div`
	font-size: 9px;
	position: absolute;
	bottom: 5px;

	${({ isBot }) =>
		isBot ? ' right:10px ; color: black;' : ' right:10px ;color: black;'};
`

export const TypingIndicator = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
`
