// DitPolicyViewer.jsx
import React from 'react'
import { Collapse, Descriptions, Switch, List, Tag } from 'antd'
import countries from './ditConditions/countries'
import moment from 'moment' // Import moment for date formatting

const DitPolicyViewer = ({ data }) => {
	const { Panel } = Collapse

	// Helper function to render arrays
	const renderArray = (arr, color) => {
		if (!arr || !Array.isArray(arr) || arr.length === 0) {
			return 'N/A'
		}
		return arr.map((item, index) => (
			<Tag key={index} style={{ marginBottom: '5px' }}>
				{item}
			</Tag>
		))
	}

	// Helper function to get operator labels
	const getOperatorLabel = (operator) => {
		switch (operator) {
			case '>':
				return 'Greater Than'
			case '<':
				return 'Less Than'
			case '=':
				return 'Equal To'
			case '>=':
				return 'Greater or Equal To'
			case '<=':
				return 'Less or Equal To'
			default:
				return operator || 'N/A' // Fallback if you have an unknown operator or empty string
		}
	}

	// Create the country code map using useMemo for performance optimization
	const countryCodeMap = React.useMemo(() => {
		return countries.reduce((map, country) => {
			map[country.code.toUpperCase()] = country.name
			return map
		}, {})
	}, [countries])

	// Helper function to get country name from formatted string
	const getCountryName = (countryString) => {
		if (!countryString) return 'N/A'

		const code = countryString.split('.')[0].toUpperCase()
		return countryCodeMap[code] || 'Unknown Country'
	}

	// Function to render countries as a comma-separated list
	const renderCountries = (countriesArray) => {
		if (
			!countriesArray ||
			!Array.isArray(countriesArray) ||
			countriesArray.length === 0
		) {
			return 'N/A'
		}

		const countryNames = countriesArray.map(getCountryName)
		return countryNames.map((country, index) => (
			<Tag color="blue" style={{ marginBottom: '5px' }}  key={index}>
				{country}
			</Tag>
		))
	}

	// Mapping from day numbers to day names
	const dayNumberToName = {
		0: 'Sunday',
		1: 'Monday',
		2: 'Tuesday',
		3: 'Wednesday',
		4: 'Thursday',
		5: 'Friday',
		6: 'Saturday',
	}

	// Helper function to format epoch milliseconds to readable date and time
	const formatEpochTime = (epochMillis, format = 'YYYY-MM-DD HH:mm') => {
		return epochMillis ? moment(epochMillis).format(format) : 'N/A'
	}

	// Helper function to render days of the week as tags
	const renderDaysOfWeek = (daysArray) => {
		if (!daysArray || !Array.isArray(daysArray) || daysArray.length === 0) {
			return 'N/A'
		}

		return daysArray.map((dayNumber, index) => (
			<Tag color="green" key={index} style={{ marginBottom: '5px' }}>
				{dayNumberToName[dayNumber] || 'Unknown'}
			</Tag>
		))
	}

	const { dit } = data

	return (
		<Collapse
			defaultActiveKey={['devices']}
			accordion
			bordered={false}
			style={{
				backgroundColor: '#ffffff',
				borderRadius: '8px',
			}}
		>
			{/* 1. DEVICES */}
			<Panel header="Devices" key="devices">
				<Descriptions
					bordered
					size="small"
					column={1}
					labelStyle={{ width: '150px' }}
				>
					<Descriptions.Item label="OS">
						{renderArray(dit.devices.os, 'gray') ?? 'N/A'}
					</Descriptions.Item>
					<Descriptions.Item label="Manufacturer">
						{renderArray(dit.devices.manufacturer, 'gray') ?? 'N/A'}
					</Descriptions.Item>
					<Descriptions.Item label="Model">
						{renderArray(dit.devices.model, 'gray') ?? 'N/A'}
					</Descriptions.Item>
					<Descriptions.Item label="Is Personal?">
						<Switch checked={dit.devices.is_personal} disabled />
					</Descriptions.Item>
				</Descriptions>
			</Panel>

			{/* 2. BEHAVIOUR */}
			<Panel header="Behaviour" key="behaviour">
				<Descriptions bordered size="small" column={1}>
					<Descriptions.Item label="Trusted Device">
						<Switch checked={dit.behaviour.is_trusted_device} disabled />
					</Descriptions.Item>
					<Descriptions.Item label="Trusted Network">
						<Switch checked={dit.behaviour.is_trusted_network} disabled />
					</Descriptions.Item>
				</Descriptions>
			</Panel>

			{/* 3. SCORES */}
			<Panel header="Scores" key="scores">
				<Descriptions bordered size="small" column={1}>
					{/* Persona */}
					<Descriptions.Item label="Persona">
						<>
							{getOperatorLabel(dit.scores.persona.operator)}{' '}
							<strong>
								{dit.scores.persona.value ? dit.scores.persona.value : ''}
							</strong>
						</>
					</Descriptions.Item>

					{/* Confidence */}
					<Descriptions.Item label="Confidence">
						<>
							{getOperatorLabel(dit.scores.confidence.operator)}{' '}
							<strong>
								{dit.scores.confidence.value ? dit.scores.confidence.value : ''}
							</strong>
						</>
					</Descriptions.Item>

					{/* Risk */}
					<Descriptions.Item label="Risk">
						<>
							{getOperatorLabel(dit.scores.risk.operator)}{' '}
							<strong>
								{dit.scores.risk.value ? dit.scores.risk.value : ''}
							</strong>
						</>
					</Descriptions.Item>

					{/* Login Fail Count */}
					<Descriptions.Item label="Login Fail Count">
						<>
							{getOperatorLabel(dit.scores.login_fail_count.operator)}{' '}
							<strong>
								{dit.scores.login_fail_count.value
									? dit.scores.login_fail_count.value
									: ''}
							</strong>
						</>
					</Descriptions.Item>

					{/* MFA Fail Count */}
					<Descriptions.Item label="MFA Fail Count">
						<>
							{getOperatorLabel(dit.scores.mfa_fail_count.operator)}{' '}
							<strong>
								{dit.scores.mfa_fail_count.value
									? dit.scores.mfa_fail_count.value
									: ' '}
							</strong>
						</>
					</Descriptions.Item>
				</Descriptions>
			</Panel>

			{/* 4. GEOLOCATION */}
			<Panel header="Geolocation" key="geolocation">
				<Descriptions bordered size="small" column={1}>
					<Descriptions.Item label="Countries">
						{renderCountries(dit.geolocation.countries)}
					</Descriptions.Item>
					<Descriptions.Item label="IP Ranges">
						{dit.geolocation.ip_ranges &&
						dit.geolocation.ip_ranges.length > 0 ? (
							<List
								size="small"
								dataSource={dit.geolocation.ip_ranges}
								renderItem={(item, index) => (
									<List.Item key={index}>
										{item.start} <strong>to</strong> {item.end}
									</List.Item>
								)}
							/>
						) : (
							'N/A'
						)}
					</Descriptions.Item>
				</Descriptions>
			</Panel>

			{/* 5. WORKFLOW */}
			<Panel header="Workflow" key="workflow">
				<Descriptions bordered size="small" column={1}>
					<Descriptions.Item label="Required Ticket">
						<Switch checked={dit.workflow.required_ticket} disabled />
					</Descriptions.Item>
					<Descriptions.Item label="Required Approval">
						<Switch checked={dit.workflow.request_to_approve} disabled />
					</Descriptions.Item>
					<Descriptions.Item label="Required Justification">
						<Switch checked={dit.workflow.need_justification} disabled />
					</Descriptions.Item>
				</Descriptions>
			</Panel>

			{/* 6. OFFLINE ACCESS */}
			<Panel header="Offline Access" key="offline_access">
				<Descriptions bordered size="small" column={1}>
					<Descriptions.Item label="Offline Access">
						<Switch checked={dit.offline_access} disabled />
					</Descriptions.Item>
				</Descriptions>
			</Panel>

			{/* 7. SCHEDULE */}
			<Panel header="Schedule" key="schedule">
				<Descriptions bordered size="small" column={1}>
					{/* Type */}
					<Descriptions.Item label="Type">
						{dit.schedule.type
							? dit.schedule.type.charAt(0).toUpperCase() +
							  dit.schedule.type.slice(1)
							: 'N/A'}
					</Descriptions.Item>

					{/* Start Time */}
					<Descriptions.Item label="Start Time">
						{dit.schedule.type === 'recurring' && dit.schedule.value.startTime
							? moment(dit.schedule.value.startTime).format('HH:mm')
							: dit.schedule.value && dit.schedule.value.startTime
							? formatEpochTime(
									dit.schedule.value.startTime,
									'YYYY-MM-DD HH:mm'
							  )
							: 'N/A'}
					</Descriptions.Item>

					{/* End Time */}
					<Descriptions.Item label="End Time">
						{dit.schedule.type === 'recurring' && dit.schedule.value.endTime
							? moment(dit.schedule.value.endTime).format('HH:mm')
							: dit.schedule.value && dit.schedule.value.endTime
							? formatEpochTime(dit.schedule.value.endTime, 'YYYY-MM-DD HH:mm')
							: 'N/A'}
					</Descriptions.Item>

					{/* Days of Week - Only for Recurring */}
					{dit.schedule.type === 'recurring' &&
						dit.schedule.value.days &&
						dit.schedule.value.days.length > 0 && (
							<Descriptions.Item label="Days of Week">
								{renderDaysOfWeek(dit.schedule.value.days)}
							</Descriptions.Item>
						)}
				</Descriptions>
			</Panel>
		</Collapse>
	)
}

export default DitPolicyViewer
