import { Button, Col, Form, Row, Select, message, Tooltip, Card, Modal } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
} from "../../../constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";

const { Option } = Select;

const POLICY_TYPES = [
  { value: "endpoints", label: "Endpoint user policies" },
  { value: "AD", label: "Group policy for a given user or groups" },
  { value: "radius", label: "Network device access policies" },
  { value: "ServiceAccount", label: "Service Account" },
];

export default function BasicPolicyInformation({ onNavigateNext }) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEditMode = searchParams.get("edit") === "true";

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [policyType, setPolicyType] = useState("");
  const [policyName, setPolicyName] = useState("");
  const [endpointsData, setEndpointsData] = useState([]);
  const [endpointGroupsData, setEndpointGroupsData] = useState([]);
  const [adGroupsData, setAdGroupsData] = useState([]);

  const [endpointUsersData, setEndpointUsersData] = useState([]);

  const [selectedEndpointGroups, setSelectedEndpointGroups] = useState([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [walletUsers, setWalletUsers] = useState([]);
  const [adUsersData, setAdUsersData] = useState([]);
  const [infraSelectedADGroups, setInfraSelectedADGroups] = useState([]);
  const [networkDevices, setNetworkDevices] = useState([]);

  const [notification] = message.useMessage();
  const [editPolicyData, setEditPolicyData] = useState(null);
  const [initialEndpointGroups, setInitialEndpointGroups] = useState([]);
  const [initialEndpoints, setInitialEndpoints] = useState([]);
  const [initialEndpointUsers, setInitialEndpointUsers] = useState([]);

  const [domainData, setDomainData] = useState([]);
  const [selectedDomainOU, setSelectedDomainOU] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [OUData, setOUData] = useState([]);

  // service accounts
  const [sourceEndpointsData, setSourceEndpointsData] = useState([]);
  //const [destinationEndpointsData, setDestinationEndpointsData] = useState([]);
  const [serviceAccountsData, setServiceAccountsData] = useState([]);
  const [selectedSourceEndpoints, setSelectedSourceEndpoints] = useState([]);
  const [selectedDestinationEndpoints, setSelectedDestinationEndpoints] = useState([]);

  const [selectedADGroups, setSelectedADGroups] = useState([]);
  const [selectDomainADPolicy, setSelectDomainADPolicy] = useState();
  const [adUsersDataADPolicy, setAdUsersDataADpolicy] = useState([]);

  useEffect(() => {
    if (isEditMode) {
      const storedData = localStorage.getItem("editPolicyData");

      if (storedData) {
        const policy = JSON.parse(storedData);
        setEditPolicyData(policy);

        const pj = policy.policyJson || {};
        const pType = pj.policyType || policy.policyType;
        const pName = pj.policyName || policy.policyName;
        setPolicyType(pType);
        setPolicyName(pName);
        const iamUsers = pj.permissions?.iam_users || [];
        // const iamGroups = pj.permissions?.iam_groups || [];

        const adUsers = pj.ad?.users || [];
        const adGroupsFromPolicy = pj.ad?.groups || [];
        const adDomains = pj.ad?.domain || [];
        const adOus = pj.ad?.ous || [];

        const sourceEndpoints = pj.serviceaccount?.source_endpoint || [];
        const destinationEndpoints = pj.serviceaccount?.destination_endpoint || [];
        const serviceAccountUsers = pj.serviceaccount?.users || [];

        const networkDevicesFromPolicy = pType === "radius" ? pj.networks?.endpoints || [] : [];

        const endpointsObject = pj.endpoints || {};
        const endpointUsers = pType === "endpoints" ? pj.endpoints?.users || [] : [];
        const endpoints = endpointsObject.groups
          ? Object.values(endpointsObject.groups).flat()
          : [];
        const endpointGroupsFromPolicy = pj.endpoints?.groups || {};
        const endpointGroups = endpointGroupsFromPolicy
          ? Object.keys(endpointGroupsFromPolicy).flat()
          : [];

        const initialValues = {
          policyType: pType,
          policyName: pName,
          iamUsers: iamUsers,
          ADGroups: adGroupsFromPolicy,
          ADUsers: adUsers,
          domain: adDomains,
          OU: adOus,

          endpointUsers: endpointUsers,
          endpoints: endpoints,
          endpointGroups: endpointGroups,
          networkDevices: networkDevicesFromPolicy,
          sourceEndpoints: sourceEndpoints,
          destinationEndpoints: destinationEndpoints,
          serviceAccountUsers: serviceAccountUsers,
        };

        form.setFieldsValue(initialValues);

        // **Set initial endpoint values**
        setInitialEndpointGroups(initialValues.endpointGroups);
        setInitialEndpoints(initialValues.endpoints);
        setInitialEndpointUsers(initialValues.endpointUsers);

        // Immediately store policyDraft.basicPolicyInformation so other components can access it
        const editPayload = {
          orgId: ORG_ID(),
          tenantId: DOMAIN_ID(),
          policyName: pName,
          policyType: pType,
          policyJson: pj,
        };
        localStorage.setItem("policyDraft.basicPolicyInformation", JSON.stringify(editPayload));
      }
    } else {
      const savedFormData = localStorage.getItem("policyDraft.basicPolicyInformation");
      if (savedFormData) {
        const policy = JSON.parse(savedFormData);
        setEditPolicyData(policy);

        const pj = policy.policyJson || {};
        const pType = pj.policyType || policy.policyType;
        const pName = pj.policyName || policy.policyName;
        setPolicyType(pType);
        setPolicyName(pName);
        const iamUsers = pj.permissions?.iam_users || [];
        // const iamGroups = pj.permissions?.iam_groups || [];

        const adUsers = pj.ad?.users || [];
        const adGroupsFromPolicy = pj.ad?.groups || [];
        const adDomains = pj.ad?.domain || [];
        const adOus = pj.ad?.ous || [];

        const sourceEndpoints = pj.serviceaccount?.source_endpoint || [];
        const destinationEndpoints = pj.serviceaccount?.destination_endpoint || [];
        const serviceAccountUsers = pj.serviceaccount?.users || [];

        const networkDevicesFromPolicy = pType === "radius" ? pj.networks?.endpoints || [] : [];

        const endpointsObject = pj.endpoints || {};
        const endpointUsers = pType === "endpoints" ? pj.endpoints?.users || [] : [];
        const endpoints = endpointsObject.groups
          ? Object.values(endpointsObject.groups).flat()
          : [];
        const endpointGroupsFromPolicy = pj.endpoints?.groups || {};
        const endpointGroups = endpointGroupsFromPolicy
          ? Object.keys(endpointGroupsFromPolicy).flat()
          : [];

        const initialValues = {
          policyType: pType,
          policyName: pName,
          iamUsers: iamUsers,
          ADGroups: adGroupsFromPolicy,
          ADUsers: adUsers,

          domain: adDomains,
          OU: adOus,
          endpointUsers: endpointUsers,
          endpoints: endpoints,
          endpointGroups: endpointGroups,
          networkDevices: networkDevicesFromPolicy,

          sourceEndpoints: sourceEndpoints,
          destinationEndpoints: destinationEndpoints,
          serviceAccountUsers: serviceAccountUsers,
        };

        form.setFieldsValue(initialValues);
      } else {
        generateDynamicPolicyName("", 36);
      }
    }
  }, []);

  // Fetch base data
  useEffect(() => {
    fetchDomains();
    fetchDomainOU();
    fetchWalletUsers();
    fetchADGroups();
  }, []);

  // Fetch data based on policy type
  useEffect(() => {
    if (policyType === "endpoints") {
      fetchEndpointGroups();
    } else if (policyType === "AD" || policyType === "serviceAccountPolicy") {
      fetchEndpointGroups();
    } else if (policyType === "radius") {
      fetchNetworkDevices();
    } else if (policyType === "ServiceAccount") {
      fetchEndpointsList();
    }
  }, [policyType, selectDomainADPolicy]);

  // Fetch AD groups when domain changes

  useEffect(() => {
    if (selectDomainADPolicy) {
      fetchADUsersADpolicy();
    }
  }, [selectDomainADPolicy]);

  // Fetch endpoints when endpoint groups are selected
  useEffect(() => {
    if (selectedEndpointGroups.length > 0) {
      fetchEndpoints();
    }
  }, [selectedEndpointGroups]);

  useEffect(() => {
    if (policyType === "ServiceAccount") {
      if (selectedDestinationEndpoints.length > 0) {
        fetchServiceAccountUser();
      }
    }
    console.log("Selected Destination Endpoints: ", selectedDestinationEndpoints);
  }, [selectedDestinationEndpoints]);

  // Fetch endpoint users when endpoints are selected
  useEffect(() => {
    if (selectedEndpoints.length > 0) {
      fetchEndpointUsers();
    }
  }, [selectedEndpoints]);

  // Fetch AD users when AD groups are selected
  useEffect(() => {
    if (infraSelectedADGroups.length > 0) {
      fetchADUsers();
    }
  }, [infraSelectedADGroups]);

  const onValuesChange = (changedValues, allValues) => {
    localStorage.setItem("policyDraft.basicPolicyInformation", JSON.stringify(allValues));
  };

  //helper functions and fetches

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  };

  const generateDynamicPolicyName = (prefix, length) => {
    const characters = "0123456789";
    let result = prefix;
    const sections = [7, 8];
    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      if (sectionLength !== 8) {
        result += "-";
      }
    }
    setPolicyName(result);
    form.setFieldsValue({ policyName: result });
  };

  const fetchDomains = async () => {
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        filter: "",
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllDomains`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.domains?.map((ad, i) => ({
        id: i + 1,
        ...ad,
      }));
      setDomainData(tempData ? tempData : []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchDomainOU = async () => {
    try {
      let payload = {
        org_Id: ORG_ID(),
        tenant_Id: DOMAIN_ID(),
      };
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/listOU`, payload, {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setOUData(res?.data?.data || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchADGroups = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        pageSize: 100,
      };
      const res = await axios.post(`${REACT_APP_API}/ad/ListAdGroups`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setAdGroupsData(res?.data?.groups || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchWalletUsers = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
        pageNumber: 1,
        pageSize: 100,
        search: "",
        filter: "",
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/walletService/walletUserList`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.emailAddress,
          label: user.emailAddress,
        };
      });
      setWalletUsers(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointGroups = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/GetUniqueGroups`,
        payload
      );
      const tempData = res?.data?.groups?.map((group, index) => {
        return {
          id: index + 1,
          value: group.group_id,
          label: group.group_name,
        };
      });
      setEndpointGroupsData(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpoints = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
        group_ids: selectedEndpointGroups,
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/GetMachinesByGroupID`,
        payload
      );
      setEndpointsData(res?.data?.machines || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointUsers = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
        machine_ids: selectedEndpoints,
      };
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/GetUsers`, payload);
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.epm_user_name,
          label: user.epm_user_name,
        };
      });
      setEndpointUsersData(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchADUsers = async () => {
    const groupIds = infraSelectedADGroups.map((group) => group.id) || [];

    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        groupId: groupIds,
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllUsersByGroup`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.userName,
          label: user.userName,
        };
      });
      setAdUsersData(tempData || []);
      console.log("tempData:", tempData);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchADUsersADpolicy = async () => {
    const domainID = selectDomainADPolicy;

    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        pageNo: 1,
        pageSize: 10,
        filters: {
          emailId: "",
          status: "",
          logoname: "",
          adId: domainID,
        },
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllAdUsers`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.username,
          label: user.username,
        };
      });
      setAdUsersDataADpolicy(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchNetworkDevices = async () => {
    let payload = {
      OrgID: 105,
      DomainID: 1,
      Search: "",
      Filter: { FilterBy: "", Value: "" },
      PageSize: 100,
      PageID: 1,
      MachineType: "radius",
    };
    try {
      const res = await axios.post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempData = res?.data?.instances?.map((instance, index) => {
        return {
          id: index + 1,
          value: instance?.publicIp,
          label: instance?.hostName,
        };
      });
      setNetworkDevices(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointsList = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpoints`,
        {
          org_Id: ORG_ID(),
          tenant_Id: DOMAIN_ID(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const data = response.data;
      const combinedEndpoints = [...data.l_endpoint, ...data.w_endpoint];
      setSourceEndpointsData(combinedEndpoints);
      // setDestinationEndpointsData(combinedEndpoints);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchServiceAccountUser = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      instanceIds: selectedDestinationEndpoints,
      filter: {
        filterBy: "",
        Value: "",
      },
      search: "",
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setServiceAccountsData(res?.data?.epmUsers);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          // logOutUser();
          console.log(err);
        }
      });
  };

  const handleFinish = (values) => {
    setIsSubmitting(true);
    let groups = {};
    let adPolicyPayload = {};
    const iamGroups = values.ADGroups || [];

    if (isEditMode) {
      const unchanged =
        arraysAreEqual(values.endpointGroups, initialEndpointGroups) ||
        arraysAreEqual(values.endpoints, initialEndpoints) ||
        arraysAreEqual(values.endpointUsers, initialEndpointUsers);

      if (unchanged) {
        messageApi.error("Please change endpoint policies before proceeding.");
        setIsSubmitting(false);
        return;
      }
    }

    if (values.policyType === "endpoints" || values.policyType === "AD") {
      groups =
        values?.endpointGroups?.reduce((acc, group) => {
          const [, groupName] = group.split("|");
          acc[groupName.trim()] = [];
          return acc;
        }, {}) || {};

      const trimmedEndpoints =
        values.endpoints?.map((endpoint) => endpoint.split("|")[1]?.trim()) || [];

      const filteredEndpoints = endpointsData?.filter((endpoint) =>
        trimmedEndpoints.includes(endpoint.hostname)
      );

      filteredEndpoints?.forEach((endpoint) => {
        const endpointGroupName = endpoint.groupname?.trim();
        if (groups[endpointGroupName]) {
          groups[endpointGroupName].push(endpoint.hostname);
        }
      });
    }

    if (values.policyType === "AD") {
      // ADUsers added both to the ad property of the payload and are assumed to be stored locally.
      adPolicyPayload = {
        policyName: policyName,
        policyType: values.policyType,
        ad: {
          ous: values.OU,
          domain: values.domain,
          groups: values.ADGroups,
          users: values.ADUsers,
        },
        endpoints: {
          groups: groups ? groups : {},
          users: values.endpointUsers,
        },
        permissions: {
          allowed: true,
          iam_users: values.iamUsers,
          iam_groups: iamGroups,
        },
      };
    }

    let endpointPolicyPayload = {
      policyName: policyName,
      policyType: values.policyType,
      endpoints: {
        groups: groups ? groups : {},
        users: values.endpointUsers,
      },

      permissions: {
        allowed: true,
        iam_users: values.iamUsers,
        iam_groups: iamGroups,
      },
    };

    let radiusPolicyPayload = {
      policyName: policyName,
      policyType: values.policyType,
      networks: {
        endpoints: values.networkDevices,
      },
      permissions: {
        allowed: true,
        iam_users: values.iamUsers,
        iam_groups: iamGroups,
      },
    };

    let serviceAccountPayload = {
      policyName: policyName,
      policyType: values.policyType,
      serviceaccount: {
        source_endpoint: values.sourceEndpoints,
        destination_endpoint: values.destinationEndpoints,
        users: values.serviceAccountUsers,
      },
      permissions: {
        allowed: true,
        iam_users: values.iamUsers,
        iam_groups: iamGroups,
      },
    };

    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      policyName: policyName,
      policyType: values.policyType,
      policyJson:
        values.policyType === "endpoints"
          ? endpointPolicyPayload
          : values.policyType === "radius"
          ? radiusPolicyPayload
          : values.policyType === "AD"
          ? adPolicyPayload
          : values.policyType === "ServiceAccount"
          ? serviceAccountPayload
          : {
              permissions: {
                allowed: true,
                iam_users: values.iamUsers,
                iam_groups: iamGroups,
              },
            },
    };

    console.log("payload:", payload);

    if (isEditMode) {
      const existingDraft = localStorage.getItem("editPolicyData");
      const existingDIT = JSON.parse(existingDraft).policyJson.dit;

      if (existingDraft) {
        payload.policyJson.dit = existingDIT;
      }
    }

    localStorage.setItem("policyDraft.basicPolicyInformation", JSON.stringify(payload));
    localStorage.setItem("editPolicyData", JSON.stringify(payload));
    localStorage.setItem("ExtraData", JSON.stringify(values.endpointGroups));

    messageApi.success("Basic Policy Information saved!");
    setIsSubmitting(false);
    onNavigateNext();
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  return (
    <div>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={handleFinish}
        initialValues={{ policyName: policyName }}
      >
        <Card>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            What kind of policy would you like to create?{" "}
            <Tooltip title="Select the type of policy you want to create. Each policy type is tailored to manage specific access controls or permissions">
              <InfoCircleOutlined />
            </Tooltip>
          </span>

          <Row style={{ marginTop: 16 }} gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Policy Type"
                name="policyType"
                rules={[{ required: true, message: "Please select a policy type" }]}
              >
                <Select
                  placeholder="Select policy type"
                  value={policyType}
                  onChange={(value) => {
                    setPolicyType(value);
                  }}
                  allowClear
                >
                  {POLICY_TYPES.map((policy, index) => (
                    <Option key={index} value={policy.value}>
                      {policy.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card
          title={
            <span>
              Who will get access (from your IAM)?{" "}
              <Tooltip title="Select the users, groups, or entities who will be granted access through this policy.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          style={{ marginBottom: 16, marginTop: 16 }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span>
                    Select IAM Users &nbsp;
                    <Tooltip title="Select one or more users.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="iamUsers"
              >
                <Select mode="multiple" placeholder="Select users" disabled={!walletUsers.length}>
                  {walletUsers.map((user, index) => (
                    <Option key={index} value={user.value}>
                      {user.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {(policyType === "endpoints" || policyType === "radius" || policyType === "AD") && (
          <Card
            title={
              <span>
                What Infrastructure will they get access to?{" "}
                <Tooltip title="Specify the resources or entities this policy will apply to.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ marginBottom: 16 }}
          >
            {policyType === "endpoints" && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoint Groups &nbsp;
                        <Tooltip title="Select one or more endpoint groups.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="endpointGroups"
                    rules={[
                      {
                        required: true,
                        message: "Please select an endpoint group",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Endpoint group"
                      onChange={(value) => {
                        const selectedGroupIds = value.map((item) =>
                          parseInt(item.split("|")[0], 10)
                        );
                        setSelectedEndpointGroups(selectedGroupIds);
                      }}
                      allowClear
                    >
                      {endpointGroupsData.map((group, index) => (
                        <Option key={index} value={`${group.value}|${group.label}`}>
                          {group.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoints &nbsp;
                        <Tooltip title="Select one or more endpoints.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="endpoints"
                    rules={[{ required: true, message: "Please select endpoints" }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select endpoint"
                      onChange={(value) => {
                        const selectedEndpointIds = value.map((item) =>
                          parseInt(item.split("|")[0], 10)
                        );
                        setSelectedEndpoints(selectedEndpointIds);
                      }}
                      disabled={!selectedEndpointGroups.length}
                      allowClear
                    >
                      {endpointsData.map((endpoint, index) => (
                        <Option key={index} value={`${endpoint.machine_id}|${endpoint.hostname}`}>
                          {endpoint.hostname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoint Users &nbsp;
                        <Tooltip title="Select one or more endpoint users.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="endpointUsers"
                    rules={[
                      {
                        required: true,
                        message: "Please select endpoint users",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select endpoint users"
                      disabled={!selectedEndpoints.length}
                      allowClear
                    >
                      {endpointUsersData.map((user, index) => (
                        <Option key={index} value={user.value}>
                          {user.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {policyType === "radius" && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Network Devices &nbsp;
                        <Tooltip title="Select one or more network devices.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="networkDevices"
                    rules={[{ required: true, message: "Please select devices" }]}
                  >
                    <Select mode="multiple" placeholder="Select network devices" allowClear>
                      {networkDevices.map((device, index) => (
                        <Option key={index} value={device.value}>
                          {`${device.label} - (${device.value})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {policyType === "AD" && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Select Domain"
                    name="domain"
                    rules={[{ required: true, message: "Please select a domain" }]}
                  >
                    <Select
                      placeholder="Select domain"
                      onChange={(selectedName, option) => {
                        // Here, selectedName is the domain name (value) stored in the form.
                        // The key of the selected option is passed as a string in option.key.
                        const domainId = parseInt(option.key, 10);

                        // Find the selected domain data using domainId.
                        const selectedDomainObj = domainData.find(
                          (domain) => domain.id === domainId
                        );

                        if (selectedDomainObj) {
                          setSelectedDomain(selectedDomainObj.id);
                          setSelectDomainADPolicy(selectedDomainObj.id);
                        }

                        // Filter OU data using the domainId.
                        const selectedOu = OUData.filter((ou) => ou.ad_id === domainId);
                        setSelectedDomainOU(selectedOu);
                      }}
                    >
                      {domainData.map((dom) => (
                        <Select.Option key={dom.id} value={dom.directoryName}>
                          {dom.directoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="OU"
                    name="OU"
                    rules={[{ required: true, message: "Please select OU" }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select OU"
                      allowClear
                      disabled={!selectedDomainOU.length}
                    >
                      {selectedDomainOU.map((ou) => (
                        <Option key={ou.id} value={ou.ou_name}>
                          {ou.ou_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select AD Groups &nbsp;
                        <Tooltip title="Select one or more AD groups.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="ADGroups"
                    rules={[{ required: true, message: "Please select a group" }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select group"
                      onChange={(value) => {
                        const selected = adGroupsData.filter((group) =>
                          value.includes(group.groupName)
                        );
                        setInfraSelectedADGroups(selected);
                      }}
                    >
                      {adGroupsData.map((group, index) => (
                        <Option key={index} value={group.groupName}>
                          {group.groupName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select AD Users &nbsp;
                        <Tooltip title="Select one or more AD users.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="ADUsers"
                  >
                    <Select mode="multiple" placeholder="Select User">
                      {adUsersDataADPolicy.map((user, index) => (
                        <Option key={index} value={user.value}>
                          {user.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Card>
        )}

        {policyType === "ServiceAccount" && (
          <Card
            title={
              <span>
                What Infrastructure will they get access to?{" "}
                <Tooltip title="Specify the resources or entities this policy will apply to.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ marginBottom: 16 }}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span>
                      Select Source Endpoints &nbsp;
                      <Tooltip title="Select one or more source endpoints">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="sourceEndpoints"
                  rules={[
                    {
                      required: true,
                      message: "Please select source endpoints",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select source endpoints"
                    onChange={(value) => setSelectedSourceEndpoints(value)}
                    allowClear
                  >
                    {sourceEndpointsData.map((endpoint, index) => (
                      <Option key={index} value={endpoint.host_name}>
                        {`${endpoint.host_name} - (${endpoint.public_ip})`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span>
                      Select Destination Endpoints &nbsp;
                      <Tooltip title="Select one or more source endpoints">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="destinationEndpoints"
                  rules={[
                    {
                      required: true,
                      message: "Please select destination endpoints",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select destination endpoints"
                    onChange={(value) => {
                      // console.log("destination endpoint value", value);
                      const selectedEndpointIds = value.map((item) =>
                        parseInt(item.split("|")[1], 10)
                      );
                      setSelectedDestinationEndpoints(selectedEndpointIds);
                    }}
                    allowClear
                  >
                    {sourceEndpointsData.map((endpoint, index) => (
                      <Option
                        key={index}
                        value={`${endpoint.host_name}|${endpoint.id}`}
                        disabled={selectedSourceEndpoints?.includes(endpoint.host_name)}
                      >
                        {`${endpoint.host_name} - (${endpoint.public_ip})`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span>
                      Select Service Account Users &nbsp;
                      <Tooltip title="Select one or more service account">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="serviceAccountUsers"
                  rules={[
                    {
                      required: true,
                      message: "Please select service account",
                    },
                  ]}
                >
                  <Select mode="multiple" placeholder="Select service account" allowClear>
                    {serviceAccountsData.map((user, index) => (
                      <Option key={index} value={user?.userName}>
                        {user?.userName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        )}

        <Row gutter={20} justify="end">
          <Form.Item>
            <Button style={{ margin: "10px" }} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              style={{ margin: "10px" }}
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isEditMode ? "Update and Next" : "Save and Next"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
