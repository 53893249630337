import React from 'react'
import { Form, Row, Col, Select, InputNumber, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Option } = Select

// Comparator options for numeric fields
const comparatorOptions = [
	{ label: 'Greater Than', value: '>' },
	{ label: 'Less Than', value: '<' },
	{ label: 'Equals', value: '==' },
	{ label: 'Greater or Equals', value: '>=' },
	{ label: 'Less or Equals', value: '<=' },
]

// Options for boolean fields
const booleanFilterOptions = [
	{ label: 'Any', value: 'any' },
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
]

const UserBehaviorAnalyticsFilterFields = ({ form, filters, setFilters }) => {
	return (
		<>
			{/* Trusted Network Filter */}
			<Form.Item
				label={
					<span>
						Trusted Network{' '}
						<Tooltip title="Filter policies based on whether trusted network detection is enabled.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['userBehaviorAnalytics', 'trusted_network']}
			>
				<Select
					placeholder="Select an option"
					allowClear
					onChange={(value) => {
						setFilters({
							...filters,
							userBehaviorAnalytics: {
								...filters.userBehaviorAnalytics,
								trusted_network: value,
							},
						})
					}}
				>
					{booleanFilterOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			</Form.Item>

			{/* Trusted Device Filter */}
			<Form.Item
				label={
					<span>
						Trusted Device{' '}
						<Tooltip title="Filter policies based on whether trusted device detection is enabled.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['userBehaviorAnalytics', 'trusted_device']}
			>
				<Select
					placeholder="Select an option"
					allowClear
					onChange={(value) => {
						setFilters({
							...filters,
							userBehaviorAnalytics: {
								...filters.userBehaviorAnalytics,
								trusted_device: value,
							},
						})
					}}
				>
					{booleanFilterOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			</Form.Item>

			{/* Login Fail Count Filter */}
			<Form.Item
				label={
					<span>
						Login Fail Count{' '}
						<Tooltip title="Filter policies based on the number of allowed login failures.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['userBehaviorAnalytics', 'login_fail_count']}
				rules={[
					{
						validator: (_, value) => {
							if (value) {
								const { comparator, value: val } = value
								if (!comparator || val === undefined) {
									return Promise.reject(
										new Error(
											'Both comparator and value are required for Login Fail Count.'
										)
									)
								}
							}
							return Promise.resolve()
						},
					},
				]}
			>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item
							name={['userBehaviorAnalytics', 'login_fail_count', 'comparator']}
							noStyle
						>
							<Select
								placeholder="Comparator"
								allowClear
								style={{ width: '100%' }}
								onChange={(value) => {
									setFilters({
										...filters,
										userBehaviorAnalytics: {
											...filters.userBehaviorAnalytics,
											login_fail_count: {
												...filters.userBehaviorAnalytics?.login_fail_count,
												comparator: value,
												// Reset value if comparator is cleared
												value:
													value !== undefined
														? filters.userBehaviorAnalytics?.login_fail_count
																?.value
														: undefined,
											},
										},
									})
								}}
							>
								{comparatorOptions.map((comp) => (
									<Option key={comp.value} value={comp.value}>
										{comp.label}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['userBehaviorAnalytics', 'login_fail_count', 'value']}
							noStyle
						>
							<InputNumber
								style={{ width: '100%' }}
								placeholder="Value"
								min={0}
								max={100} // Adjust as needed
								step={1}
								onChange={(value) => {
									setFilters({
										...filters,
										userBehaviorAnalytics: {
											...filters.userBehaviorAnalytics,
											login_fail_count: {
												...filters.userBehaviorAnalytics?.login_fail_count,
												value: value,
											},
										},
									})
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form.Item>

			{/* MFA Fail Count Filter */}
			<Form.Item
				label={
					<span>
						MFA Fail Count{' '}
						<Tooltip title="Filter policies based on the number of allowed MFA failures.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['userBehaviorAnalytics', 'mfa_fail_count']}
				rules={[
					{
						validator: (_, value) => {
							if (value) {
								const { comparator, value: val } = value
								if (!comparator || val === undefined) {
									return Promise.reject(
										new Error(
											'Both comparator and value are required for MFA Fail Count.'
										)
									)
								}
							}
							return Promise.resolve()
						},
					},
				]}
			>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item
							name={['userBehaviorAnalytics', 'mfa_fail_count', 'comparator']}
							noStyle
						>
							<Select
								placeholder="Comparator"
								allowClear
								style={{ width: '100%' }}
								onChange={(value) => {
									setFilters({
										...filters,
										userBehaviorAnalytics: {
											...filters.userBehaviorAnalytics,
											mfa_fail_count: {
												...filters.userBehaviorAnalytics?.mfa_fail_count,
												comparator: value,
												// Reset value if comparator is cleared
												value:
													value !== undefined
														? filters.userBehaviorAnalytics?.mfa_fail_count
																?.value
														: undefined,
											},
										},
									})
								}}
							>
								{comparatorOptions.map((comp) => (
									<Option key={comp.value} value={comp.value}>
										{comp.label}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['userBehaviorAnalytics', 'mfa_fail_count', 'value']}
							noStyle
						>
							<InputNumber
								style={{ width: '100%' }}
								placeholder="Value"
								min={0}
								max={100} // Adjust as needed
								step={1}
								onChange={(value) => {
									setFilters({
										...filters,
										userBehaviorAnalytics: {
											...filters.userBehaviorAnalytics,
											mfa_fail_count: {
												...filters.userBehaviorAnalytics?.mfa_fail_count,
												value: value,
											},
										},
									})
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form.Item>

			{/* Disable Logon Filter */}
			<Form.Item
				label={
					<span>
						Disable Logon{' '}
						<Tooltip title="Filter policies based on whether logon is disabled.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['userBehaviorAnalytics', 'disable_logon']}
			>
				<Select
					placeholder="Select an option"
					allowClear
					onChange={(value) => {
						setFilters({
							...filters,
							userBehaviorAnalytics: {
								...filters.userBehaviorAnalytics,
								disable_logon: value,
							},
						})
					}}
				>
					{booleanFilterOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			</Form.Item>
		</>
	)
}

export default UserBehaviorAnalyticsFilterFields
