import React, { useState, useEffect } from 'react'
import { Layout, Menu, Breadcrumb, Typography, message } from 'antd'
import {
	FormOutlined,
	FieldTimeOutlined,
	RetweetOutlined,
	GlobalOutlined,
	LaptopOutlined,
	PropertySafetyOutlined,
	TeamOutlined,
	FileTextOutlined,
	CompassOutlined,
	HomeOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { AUTH_TOKEN } from '../../constants'

import BasicPolicyInformation from './ditConditions/BasicPolicyInformation'
import TimeBoundAccess from './ditConditions/TimeBoundAccess'
import JustInTime from './ditConditions/JustInTime'
import PolicyWorkflow from './ditConditions/PolicyWorkflow'
import OfflineAccess from './ditConditions/OfflineAccess'
import IdentityRisk from './ditConditions/IdentityRisk'
import UserBehavioralAnalytics from './ditConditions/UserBehavioralAnalytics'
import LocationAccess from './ditConditions/LocationAccess'
import NetworkAccess from './ditConditions/NetworkAccess'
import DevicePolicy from './ditConditions/DevicesPolicies'

import { logOutUser } from '../../common'

const { Header, Sider, Content } = Layout
const { Title } = Typography

const SIDEBAR_MENU = [
	{
		key: '1',
		icon: <FormOutlined />,
		label: 'Basic Policy Information',
		description: 'Define the foundational settings and parameters for your policy.',
	},
	{
		key: '2',
		icon: <FieldTimeOutlined />,
		label: 'TimeBound Access',
		description: 'Configure time-based access restrictions for users.',
	},
	{
		key: '3',
		icon: <RetweetOutlined />,
		label: 'Just in Time',
		description: 'Set up just-in-time access provisions to enhance security.',
	},
	{
		key: '4',
		icon: <PropertySafetyOutlined />,
		label: 'Policy Workflow',
		description: 'Manage the workflow and approval processes for policies.',
	},
	{
		key: '5',
		icon: <GlobalOutlined />,
		label: 'Offline Access',
		description: 'Control and monitor offline access to resources.',
	},
	{
		key: '6',
		icon: <CompassOutlined />,
		label: 'Location Access',
		description: 'Restrict or allow access based on geographic locations.',
	},
	{
		key: '7',
		icon: <LaptopOutlined />,
		label: 'Network Access',
		description: 'Manage network-related access controls and permissions.',
	},
	{
		key: '8',
		icon: <PropertySafetyOutlined />,
		label: 'Identity Risk',
		description: 'Assess and mitigate identity-related security risks.',
	},
	{
		key: '9',
		icon: <TeamOutlined />,
		label: 'User Behavioral Analytics',
		description: 'Analyze user behavior to detect and prevent anomalies.',
	},
	{
		key: '10',
		icon: <LaptopOutlined />,
		label: 'Device Policies',
		description: 'Define policies for devices and their usage.',
	},
]

const componentsMap = {
	1: BasicPolicyInformation,
	2: TimeBoundAccess,
	3: JustInTime,
	4: PolicyWorkflow,
	5: OfflineAccess,
	6: LocationAccess,
	7: NetworkAccess,
	8: IdentityRisk,
	9: UserBehavioralAnalytics,
	10: DevicePolicy,
}

const CreatePolicyPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const isEditMode = searchParams.get('edit') === 'true'

	const [selectedMenuKey, setSelectedMenuKey] = useState('1')
	const SelectedComponent = componentsMap[selectedMenuKey]
	const currentMenuItem = SIDEBAR_MENU.find((menu) => menu.key === selectedMenuKey)
	const [loading, setLoading] = useState(false)

	// If in edit mode, retrieve the policy id from localStorage
	const [policyId, setPolicyId] = useState(null)

	useEffect(() => {
		if (isEditMode) {
			const storedData = localStorage.getItem('storedDataId')
			if (storedData) {
				setPolicyId(storedData)
			}
		}
	}, [isEditMode])

	const handleApiCall = (payload) => {
		setLoading(true)

		const finalPayload = {
			...payload,
			generatedBy: 'Manual',
		}

		if (isEditMode && policyId) {
			console.log('payload', JSON.stringify(payload, null, 2))
			// Update request if edit mode is true
			axios
				.post(
					`${process.env.REACT_APP_API}/api/v1/policyService/UpdateJSONPolicy`,
					{ ...finalPayload, id: policyId, updatedBy: 'admin_user' },
					{
						headers: {
							withCredentials: true,
							'X-Authorization': AUTH_TOKEN(),
						},
					}
				)
				.then(() => {
					message.success('Policy updated successfully!')
					localStorage.removeItem('policyDraft.basicPolicyInformation')
					localStorage.removeItem('formSessionData')
					localStorage.removeItem('editPolicyData')
					navigate('/pam/policy/v2')
				})
				.catch((err) => {
					if (
						err?.response?.status === 401 ||
						err.response.data?.Message === 'Invalid credentials' ||
						err.response.data?.Message === 'Unauthorized'
					) {
						//logOutUser()
					} else if (
						err?.response?.status === 401 &&
						err.response.data?.Validation === false &&
						err.response.data?.Message === 'User Does not exist contact your administrator'
					) {
						message.error('Failed to update policy contact your administrator')
					} else {
						//logOutUser()
					}
					console.error('Failed to update policy:', err)
					message.error('Failed to update policy')
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			// Create request if not in edit mode
			axios
				.post(`${process.env.REACT_APP_API}/api/v1/policyService/CreateJSONPolicy`, finalPayload)
				.then(() => {
					message.success('Policy created successfully!')
					localStorage.removeItem('policyDraft.basicPolicyInformation')
					localStorage.removeItem('formSessionData')
					localStorage.removeItem('editPolicyData')
					navigate('/pam/policy/v2')
				})
				.catch((err) => {
					if (
						err?.response?.status === 401 ||
						err.response.data?.Message === 'Invalid credentials' ||
						err.response.data?.Message === 'Unauthorized'
					) {
						logOutUser()
					} else if (
						err?.response?.status === 401 &&
						err.response.data?.Validation === false &&
						err.response.data?.Message === 'User Does not exist contact your administrator'
					) {
						message.error('Failed to update policy contact your administrator')
					} else {
						logOutUser()
					}
					console.error('Failed to create policy:', err)
					message.error('Failed to create policy')
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	const handleNavigateNext = () => {
		const currentIndex = SIDEBAR_MENU.findIndex((menu) => menu.key === selectedMenuKey)
		const nextMenuItem = SIDEBAR_MENU[currentIndex + 1]

		if (nextMenuItem) {
			setSelectedMenuKey(nextMenuItem.key)
		} else {
			message.info('This is the last step of the policy creation process.')
		}
	}

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Header
				style={{
					backgroundColor: '#fff',
					overflow: 'visible',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div style={{ marginBottom: '8px' }}>
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to="/">
								<HomeOutlined />
							</Link>
						</Breadcrumb.Item>

						<Breadcrumb.Item>
							<Link to="/pam/policy/v2">
								<FileTextOutlined />
								<span style={{ fontSize: '14px', color: '#444', marginLeft: '8px' }}>Policies</span>
							</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<span style={{ marginLeft: '8px' }}>{currentMenuItem?.label}</span>
						</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div>
					<Title level={3} style={{ margin: 0 }}>
						{isEditMode ? 'Edit Policy - ' : 'Create Policy - '}
						{currentMenuItem?.label}
					</Title>
					{currentMenuItem?.description && (
						<p
							style={{
								color: '#666',
								padding: 0,
								fontSize: '14px',
								margin: '-15px 0 10px 0',
							}}
						>
							{currentMenuItem?.description}
						</p>
					)}
				</div>
			</Header>

			<Layout>
				<Content
					style={{
						padding: '20px 24px 24px 24px',
						backgroundColor: '#f9fafb',
						margin: '30px 60px 16px 16px',
						background: '#fff',
						flex: 1,
					}}
				>
					{SelectedComponent ? (
						<SelectedComponent
							onNavigateNext={handleNavigateNext}
							onCreatePolicy={handleApiCall}
							loadingPolicy={loading}
						/>
					) : (
						<div>Select a policy section from the sidebar.</div>
					)}
				</Content>

				<Sider
					width={300}
					style={{
						backgroundColor: '#fff',
						borderLeft: '1px solid #ddd',
						padding: '16px',
						overflowY: 'auto',
					}}
				>
					<Menu
						selectedKeys={[selectedMenuKey]}
						onClick={(e) => setSelectedMenuKey(e.key)}
						mode="inline"
						style={{
							height: '100%',
							border: 'none',
							fontSize: '14px',
						}}
					>
						{SIDEBAR_MENU.map((menu) => (
							<Menu.Item key={menu.key} icon={menu.icon}>
								{menu.label}
							</Menu.Item>
						))}
					</Menu>
				</Sider>
			</Layout>
		</Layout>
	)
}

export default CreatePolicyPage
