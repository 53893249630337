import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Option } = Select

const OfflineAccessFilterFields = ({ form, filters, setFilters }) => {
	return (
		<>
			<Form.Item
				label={
					<span>
						Enable Offline Access{' '}
						<Tooltip title="Filter policies based on whether offline access is enabled.">
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
				name={['offlineAccess', 'isOfflineAccessEnabled']}
			>
				<Select
					placeholder="Select an option"
					allowClear
					onChange={(value) => {
						setFilters({
							...filters,
							offlineAccess: {
								...filters.offlineAccess,
								isOfflineAccessEnabled: value,
							},
						})
					}}
				>
					<Option value="any">Any</Option>
					<Option value="yes">Yes</Option>
					<Option value="no">No</Option>
				</Select>
			</Form.Item>
		</>
	)
}

export default OfflineAccessFilterFields
