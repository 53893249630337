// src/components/AdvancedFilterDrawer/constants.js

export const daysOfWeekOptions = [
	{ label: 'Mon', value: 'Monday' },
	{ label: 'Tue', value: 'Tuesday' },
	{ label: 'Wed', value: 'Wednesday' },
	{ label: 'Thu', value: 'Thursday' },
	{ label: 'Fri', value: 'Friday' },
	{ label: 'Sat', value: 'Saturday' },
	{ label: 'Sun', value: 'Sunday' },
]

// Define policy type and condition options
export const typeOptions = [
	{ label: 'Device Ownership', value: 'deviceOwnership' },
	{ label: 'Manufacturer', value: 'manufacturer' },
	{ label: 'Model', value: 'model' },
	{ label: 'Operating System', value: 'operatingSystem' },
]

export const conditionOptions = [
	{ label: 'Equals', value: 'equals' },
	{ label: 'Not Equals', value: 'notEquals' },
]

// Dynamic options based on type
export const dynamicOptions = {
	deviceOwnership: ['Company', 'Personal'],
	manufacturer: ['Apple', 'Samsung', 'Dell', 'HP', 'Lenovo'],
	operatingSystem: ['Android', 'iOS', 'Windows', 'macOS', 'Linux'],
	model: [
		'MacBook',
		'XPS 13',
		'EliteBook',
		'ThinkPad',
		'Surface Pro',
		'Latitude',
		'Inspiron',
		'Yoga',
	],
}
