import React, { useState } from 'react'
import {
	Drawer,
	Button,
	Collapse,
	Form,
	Typography,
	Space,
	Tooltip,
	notification,
} from 'antd'
import {
	FormOutlined,
	FieldTimeOutlined,
	PropertySafetyOutlined,
	GlobalOutlined,
	CompassOutlined,
	LaptopOutlined,
	TeamOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons'

// Import filter field components
import {
	TimeBoundAccessFields,
	PolicyWorkflowFields,
	OfflineAccessFields,
	LocationAccessFields,
	NetworkAccessFields,
	IdentityRiskFields,
	UserBehavioralAnalyticsFields,
	DevicePoliciesFields,
} from './filters'

const { Panel } = Collapse
const { Text } = Typography

const SIDEBAR_MENU = [
	{
		key: '2',
		icon: <FieldTimeOutlined />,
		label: 'TimeBound Access',
		description: 'Configure time-based access restrictions for users.',
		component: TimeBoundAccessFields,
	},
	{
		key: '4',
		icon: <PropertySafetyOutlined />,
		label: 'Policy Workflow',
		description: 'Manage the workflow and approval processes for policies.',
		component: PolicyWorkflowFields,
	},
	{
		key: '5',
		icon: <GlobalOutlined />,
		label: 'Offline Access',
		description: 'Control and monitor offline access to resources.',
		component: OfflineAccessFields,
	},
	{
		key: '6',
		icon: <CompassOutlined />,
		label: 'Location Access',
		description: 'Restrict or allow access based on geographic locations.',
		component: LocationAccessFields,
	},
	{
		key: '7',
		icon: <LaptopOutlined />,
		label: 'Network Access',
		description: 'Manage network-related access controls and permissions.',
		component: NetworkAccessFields,
	},
	{
		key: '8',
		icon: <PropertySafetyOutlined />,
		label: 'Identity Risk',
		description: 'Assess and mitigate identity-related security risks.',
		component: IdentityRiskFields,
	},
	{
		key: '9',
		icon: <TeamOutlined />,
		label: 'User Behavioral Analytics',
		description: 'Analyze user behavior to detect and prevent anomalies.',
		component: UserBehavioralAnalyticsFields,
	},
	{
		key: '10',
		icon: <LaptopOutlined />,
		label: 'Device Policies',
		description: 'Define policies for devices and their usage.',
		component: DevicePoliciesFields,
	},
]

const AdvancedFilters = () => {
	const [visible, setVisible] = useState(false)
	const [activeKeys, setActiveKeys] = useState([])
	const [form] = Form.useForm()
	const [accessType, setAccessType] = useState('One Time') // For TimeBoundAccessFields

	// Centralized state for all filters
	const [filters, setFilters] = useState({})

	const showDrawer = () => {
		setVisible(true)
	}

	const closeDrawer = () => {
		setVisible(false)
		form.resetFields()
		setActiveKeys([])
	}

	const handleCreateFilter = () => {
		form
			.validateFields()

			.then((values) => {
				// Update the centralized filters state with form values
				setFilters(values)

				// Integrated JSON of all applied filters
				console.log('Created Filter:', values)
				notification.success({
					message: 'Success',
					description: 'Filters applied successfully!',
					placement: 'bottomRight',
				})
				closeDrawer()
			})
			.catch((info) => {
				console.log('Validate Failed:', info)
			})
	}

	return (
		<div>
			<Button onClick={showDrawer} icon={<FormOutlined />}>
				Advanced Filters
			</Button>
			<Drawer
				title={
					<Space>
						<Text strong style={{ fontSize: '20px', color: '#333' }}>
							Advanced Filters
						</Text>
					</Space>
				}
				placement="right"
				width={480}
				onClose={closeDrawer}
				visible={visible}
				bodyStyle={{ padding: '24px', backgroundColor: '#ffffff' }}
				headerStyle={{ borderBottom: '1px solid #f0f0f0' }}
				footer={
					<div
						style={{
							textAlign: 'right',
							borderTop: '1px solid #f0f0f0',
							paddingTop: '16px',
							backgroundColor: '#ffffff',
						}}
					>
						<Button
							onClick={closeDrawer}
							style={{
								marginRight: '12px',
								color: '#555',
								borderColor: '#d9d9d9',
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={handleCreateFilter}
							className="apply-filters-button"
						>
							Apply Filters
						</Button>
					</div>
				}
			>
				<Form
					form={form}
					layout="vertical"
					style={{ maxHeight: '70vh', overflowY: 'auto' }}
				>
					<Collapse
						activeKey={activeKeys}
						onChange={(keys) => setActiveKeys(keys)}
						accordion
						bordered={false}
						style={{
							backgroundColor: '#ffffff',
							borderRadius: '8px',
						}}
					>
						{SIDEBAR_MENU.map((item) => {
							const Component = item.component
							return (
								<Panel
									key={item.key}
									header={
										<Space>
											{React.cloneElement(item.icon, {
												style: { color: 'grey', fontSize: '16px' },
											})}
											<span style={{ fontSize: '20px', color: '#333' }}>
												{item.label}
											</span>
											<Tooltip title={item.description}>
												<QuestionCircleOutlined style={{ color: '#8c8c8c' }} />
											</Tooltip>
										</Space>
									}
									style={{
										backgroundColor: '#ffffff',
										marginBottom: '8px',
										borderRadius: '6px',
									}}
								>
									<Component
										form={form}
										accessType={accessType}
										setAccessType={setAccessType}
										filters={filters}
										setFilters={setFilters}
									/>
								</Panel>
							)
						})}
					</Collapse>
				</Form>
			</Drawer>
		</div>
	)
}

export default AdvancedFilters
