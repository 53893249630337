import React, { useState, useEffect, useRef } from "react";
import { Drawer, Button, List, Avatar, Typography, Space } from "antd";
import { MessageOutlined, UserOutlined } from "@ant-design/icons";
import {
  ChatContainer,
  MessagesContainer,
  MessageWrapper,
  MessageBubble,
  AvatarWrapper,
  OptionsContainer,
  TypingIndicator,
  Dot,
  Timestamp,
  OptionButton,
} from "./AIAgent.styles";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import { REACT_APP_AI_API_URL } from "../../../constants";

const { Text } = Typography;

const messageVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

const AIAgent = ({ fetchPolicyData, applyFilter }) => {
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([
    {
      key: 1,
      role: "bot",
      content:
        "Hi! I'm your AuthNull AI Agent. I’m here to assist you with managing your policies. How can I help you today?",
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    },
  ]);
  const [options, setOptions] = useState([
    { key: "create_policy", label: "Create a policy" },
    { key: "view_policies", label: "Show all AI-generated policies" },
  ]);

  const [selectedPolicyType, setSelectedPolicyType] = useState();
  const [selectedCannedMessage, setSelectedCannedMessage] = useState("");

  const [isTyping, setIsTyping] = useState(false);
  const messageId = useRef(2);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (selectedPolicyType === "endpoints") {
      generateAIPolicy();
      setSelectedPolicyType("");
    }
    if (selectedCannedMessage === "view_policies") {
      viewAIPolicy();
    }
  }, [selectedPolicyType, selectedCannedMessage]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleUserSelection = async (optionKey) => {
    let userMessage = {};
    let botResponse = "";
    let newOptions = [];

    switch (optionKey) {
      case "create_policy":
        userMessage = {
          key: messageId.current++,
          role: "user",
          content: "Create a policy.",
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        botResponse = "Sure! What type of policy would you like to create?";
        newOptions = [
          { key: "endpoints", label: "Endpoints" },
          { key: "Active Directory", label: "Active Directory" },
          { key: "Radius", label: "Radius" },
          { key: "end_chat", label: "End Chat" },
        ];
        break;

      case "view_policies":
        userMessage = {
          key: messageId.current++,
          role: "user",
          content: "Show all AI-generated policies?",
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        botResponse = "Absolutely! Here are your all AI-generated policies.";
        newOptions = [
          { key: "create_policy", label: "Create Policy" },
          { key: "end_chat", label: "End Chat" },
        ];
        break;

      // Sub cases for Create policy
      case "endpoints":
        userMessage = {
          key: messageId.current++,
          role: "user",
          content: "I want an endpoints policy.",
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        botResponse = "Sure! let me create an Endpoint policy for you.";
        newOptions = [
          { key: "create_policy", label: "Create one more policy" },
          { key: "view_policies", label: "Show all AI-generated policies" },
          { key: "end_chat", label: "End Chat" },
        ];
        break;

      case "end_chat":
        userMessage = {
          key: messageId.current++,
          role: "user",
          content: "That's all for now. Thank you!",
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        botResponse = "You're welcome! If you need further assistance, feel free to reach out.";
        newOptions = [
          { key: "create_policy", label: "Create a policy" },
          { key: "view_policies", label: "Show all AI-generated policies" },
        ];
        break;

      default:
        userMessage = {
          key: messageId.current++,
          role: "user",
          content: "I need assistance.",
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        botResponse = "How can I assist you today?";
        newOptions = [
          { key: "create_policy", label: "Create a policy" },
          { key: "view_policies", label: "Show all AI-generated policies" },
        ];
        break;
    }

    // Append user message
    setMessages((prev) => [...prev, userMessage]);
    setOptions([]);

    // Show typing indicator
    setIsTyping(true);

    // Simulate typing effect
    await simulateTyping(botResponse);

    // Remove typing indicator
    setIsTyping(false);

    // Update options
    setOptions(newOptions);
  };

  // Function to simulate typing effect (letter by letter with smooth animations)
  const simulateTyping = (text) => {
    return new Promise((resolve) => {
      let currentText = "";
      const letters = text.split("");
      let index = 0;
      const minDelay = 30;
      const maxDelay = 100;

      const typeLetter = () => {
        if (index < letters.length) {
          currentText += letters[index];
          setMessages((prev) => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage.role === "bot" && lastMessage.content !== text) {
              // Update the last bot message
              return [
                ...prev.slice(0, -1),
                {
                  ...lastMessage,
                  content: currentText,
                },
              ];
            } else {
              // Append a new bot message
              return [
                ...prev,
                {
                  key: messageId.current++,
                  role: "bot",
                  content: currentText,
                  timestamp: new Date().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
                },
              ];
            }
          });
          index++;
          const delay = Math.random() * (maxDelay - minDelay) + minDelay;
          setTimeout(typeLetter, delay);
        } else {
          resolve();
        }
      };

      typeLetter();
    });
  };

  // Ensure the latest messages are visible
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isTyping]);

  // Function to render message content with animated letters
  const renderMessageContent = (content) => {
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={messageVariants}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        {content}
      </motion.div>
    );
  };

  const generateAIPolicy = async () => {
    axios
      .get(`${REACT_APP_AI_API_URL}/api/v1/ai/generate-policy`)
      .then((res) => {
        console.log("AI Generated Policy:", res?.data);
        fetchPolicyData();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const viewAIPolicy = () => {
    applyFilter("generatedBy", "AI");
  };

  return (
    <div>
      <Button
        onClick={showDrawer}
        //icon={<OpenAIOutlined />}
        style={{
          background: "linear-gradient(90deg, #379fff, #645cff)",
          color: "#fff",
          marginRight: "1rem",
        }}
      >
        ✨AuthNull AI Agent
      </Button>

      <Drawer
        title="AuthNull AI Agent"
        placement="right"
        width={600} // Increased width for better fit
        onClose={onClose}
        visible={visible}
        closeIcon={<MessageOutlined style={{ color: "#ffffff" }} />}
        bodyStyle={{ padding: 0, backgroundColor: "#f5f5f5" }} // Light, neutral background
        headerStyle={{
          background: "linear-gradient(135deg, #4b6cb7, #182848)",
          color: "#ffffff",
          textAlign: "center",
          fontSize: "20px",
          padding: "20px",
        }}
        height="80vh"
      >
        <ChatContainer>
          <MessagesContainer>
            <List
              dataSource={messages}
              renderItem={(msg, index) => {
                // Determine if the previous message is from the same sender
                const isSameSender = index > 0 && messages[index - 1].role === msg.role;

                return (
                  <AnimatePresence key={msg.key}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={messageVariants}
                      transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                      <MessageWrapper isBot={msg.role === "bot"} isSameSender={isSameSender}>
                        {msg.role === "bot" && (
                          <AvatarWrapper isBot={true}>
                            <Avatar
                              style={{ backgroundColor: "#4b6cb7" }}
                              icon={<MessageOutlined />}
                            />
                          </AvatarWrapper>
                        )}
                        <MessageBubble isBot={msg.role === "bot"}>
                          {msg.role === "bot" ? (
                            renderMessageContent(msg.content)
                          ) : (
                            <Text style={{ whiteSpace: "pre-wrap" }}>{msg.content}</Text>
                          )}
                          {/* Optional Timestamp */}
                          <Timestamp isBot={msg.role === "bot"}>{msg.timestamp}</Timestamp>
                        </MessageBubble>
                        {msg.role === "user" && (
                          <AvatarWrapper isBot={false}>
                            <Avatar
                              style={{ backgroundColor: "#379fff" }}
                              icon={<UserOutlined />}
                            />
                          </AvatarWrapper>
                        )}
                      </MessageWrapper>
                    </motion.div>
                  </AnimatePresence>
                );
              }}
            />
            {isTyping && (
              <TypingIndicator>
                <AvatarWrapper isBot={true}>
                  <Avatar style={{ backgroundColor: "#4b6cb7" }} icon={<MessageOutlined />} />
                </AvatarWrapper>
                <MessageBubble isBot={true}>
                  <Space>
                    <Dot />
                    <Dot />
                    <Dot />
                  </Space>
                </MessageBubble>
              </TypingIndicator>
            )}
            <div ref={messagesEndRef} />
          </MessagesContainer>

          {/* Predefined Options */}
          {options.length > 0 && (
            <OptionsContainer>
              <Space style={{ width: "100%" }}>
                {options.map((option) => (
                  <OptionButton
                    key={option.key}
                    onClick={() => {
                      handleUserSelection(option.key);
                      if (option.key === "endpoints") {
                        setSelectedPolicyType("endpoints");
                      } else if (option.key === "view_policies") {
                        setSelectedCannedMessage("view_policies");
                      }
                    }}
                  >
                    {option.label}
                  </OptionButton>
                ))}
              </Space>
            </OptionsContainer>
          )}
        </ChatContainer>
      </Drawer>
    </div>
  );
};

export default AIAgent;
