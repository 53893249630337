// src/components/AdvancedFilterDrawer/filters/DevicePoliciesFilter.js

import React from 'react'
import { Form, Select, Button, Row, Col, Tooltip, Divider } from 'antd'
import {
	PlusOutlined,
	MinusCircleOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons'
import { typeOptions, conditionOptions, dynamicOptions } from '../constants'

const { Option } = Select

const DevicePoliciesFilter = ({ form, filters, setFilters }) => {
	return (
		<Form.List name="devicePolicies">
			{(fields, { add, remove }) => (
				<>
					{fields.map(({ key, name, ...restField }) => (
						<div key={key} style={{ marginBottom: 24 }}>
							<Row gutter={16} align="middle">
								{/* Type Field */}
								<Col span={6}>
									<Form.Item
										{...restField}
										name={[name, 'type']}
										label={
											<span>
												Type{' '}
												<Tooltip title="Select the type of device policy.">
													<InfoCircleOutlined />
												</Tooltip>
											</span>
										}
										rules={[
											{ required: true, message: 'Please select a type.' },
										]}
									>
										<Select
											placeholder="Select Type"
											allowClear
											onChange={(value) => {
												// Ensure filters.devicePolicies is an array
												const updatedDevicePolicies = Array.isArray(
													filters.devicePolicies
												)
													? [...filters.devicePolicies]
													: []

												// Update the 'type' in filters.devicePolicies[name].type
												updatedDevicePolicies[name] = {
													...updatedDevicePolicies[name],
													type: value,
													// Reset options if type changes
													options: undefined,
												}

												setFilters({
													...filters,
													devicePolicies: updatedDevicePolicies,
												})
											}}
										>
											{typeOptions.map((type) => (
												<Option key={type.value} value={type.value}>
													{type.label}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>

								{/* Condition Field */}
								<Col span={6}>
									<Form.Item
										{...restField}
										name={[name, 'condition']}
										label={
											<span>
												Condition{' '}
												<Tooltip title="Select the condition for the device policy.">
													<InfoCircleOutlined />
												</Tooltip>
											</span>
										}
										rules={[
											{ required: true, message: 'Please select a condition.' },
										]}
									>
										<Select
											placeholder="Select Condition"
											allowClear
											onChange={(value) => {
												const updatedDevicePolicies = Array.isArray(
													filters.devicePolicies
												)
													? [...filters.devicePolicies]
													: []

												updatedDevicePolicies[name] = {
													...updatedDevicePolicies[name],
													condition: value,
												}

												setFilters({
													...filters,
													devicePolicies: updatedDevicePolicies,
												})
											}}
										>
											{conditionOptions.map((condition) => (
												<Option key={condition.value} value={condition.value}>
													{condition.label}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item
										{...restField}
										name={[name, 'options']}
										label={
											<span>
												Options{' '}
												<Tooltip title="Select the options based on the selected type.">
													<InfoCircleOutlined />
												</Tooltip>
											</span>
										}
										rules={[
											{ required: true, message: 'Please provide options.' },
										]}
									>
										<Form.Item
											noStyle
											shouldUpdate={(prevValues, currentValues) =>
												prevValues.devicePolicies?.[name]?.type !==
												currentValues.devicePolicies?.[name]?.type
											}
										>
											{({ getFieldValue, setFieldsValue }) => {
												// Get the 'type' field value for this specific device policy
												const type = getFieldValue([
													'devicePolicies',
													name,
													'type',
												])
												const options = dynamicOptions[type] || []
												const isMultiple =
													type === 'model' || type === 'operatingSystem'

												return (
													<Select
														mode={isMultiple ? 'multiple' : undefined}
														placeholder="Select Options"
														allowClear
														disabled={!type} // Disable if no type is selected
														onChange={(value) => {
															// Update the form state directly
															setFieldsValue({
																devicePolicies: getFieldValue(
																	'devicePolicies'
																).map((policy, index) =>
																	index === name
																		? { ...policy, options: value }
																		: policy
																),
															})

															// Ensure filters.devicePolicies stays in sync
															const updatedDevicePolicies = Array.isArray(
																filters.devicePolicies
															)
																? [...filters.devicePolicies]
																: []

															updatedDevicePolicies[name] = {
																...updatedDevicePolicies[name],
																options: value,
															}

															setFilters({
																...filters,
																devicePolicies: updatedDevicePolicies,
															})
														}}
													>
														{options.map((option) => (
															<Option key={option} value={option}>
																{option}
															</Option>
														))}
													</Select>
												)
											}}
										</Form.Item>
									</Form.Item>
								</Col>



								<Col span={4}>
									<Button
										type="link"
										icon={<MinusCircleOutlined />}
										danger
										onClick={() => {
											remove(name)

											// Ensure filters.devicePolicies is an array
											if (Array.isArray(filters.devicePolicies)) {
												const updatedDevicePolicies =
													filters.devicePolicies.filter(
														(_, index) => index !== name
													)
												setFilters({
													...filters,
													devicePolicies: updatedDevicePolicies,
												})
											}
										}}
									></Button>
								</Col>
							</Row>
							<Divider />
						</div>
					))}

					{/* Add Policy Button */}
					<Form.Item>
						<Button
							type="dashed"
							onClick={() => {
								add()

								// Ensure filters.devicePolicies is an array
								const updatedDevicePolicies = Array.isArray(
									filters.devicePolicies
								)
									? [...filters.devicePolicies, {}]
									: [{}]

								setFilters({
									...filters,
									devicePolicies: updatedDevicePolicies,
								})
							}}
							icon={<PlusOutlined />}
							block
						>
							Add Device Filter
						</Button>
					</Form.Item>
				</>
			)}
		</Form.List>
	)
}

export default DevicePoliciesFilter